<template>
  <b-modal
    :visible="isVisible"
    :title="$t('calendar.meeting_details')"
    :ok-title="$t('general.confirm')"
    centered
    modal-class="dark-modal"
    header-class="dark-modal-header"
    body-class="dark-modal-body"
    footer-class="dark-modal-footer"
    @ok="handleSubmit"
    @hidden="onHidden"
  >
    <ValidationObserver ref="form" tag="div">
      <b-form>
        <!-- Meeting Name -->
        <ValidationProvider
          :name="$t('general.name')"
          rules="required"
          vid="userName"
          mode="lazy"
          tag="div"
          v-slot="{ errors }"
        >
          <div class="form-group">
            <label for="user-name">{{ $t('calendar.your_name') }} *</label>
            <input
              class="form-control"
              id="user-name"
              type="text"
              v-model="meetingData.userName"
              :placeholder="$t('calendar.placeholder.enter_your_full_name')"
            />
            <div class="error-message" v-if="errors[0]">
              {{ errors[0] }}
            </div>
          </div>
        </ValidationProvider>

        <!-- Email Address -->
        <ValidationProvider
          :name="$t('general.email')"
          rules="required|email"
          vid="emailAddress"
          mode="lazy"
          tag="div"
          v-slot="{ errors }"
        >
          <div class="form-group">
            <label for="email-address"
              >{{ $t('calendar.email_address') }} *</label
            >
            <input
              class="form-control"
              id="email-address"
              type="email"
              v-model="meetingData.emailAddress"
              placeholder="example@email.com"
            />
            <div class="error-message" v-if="errors[0]">
              {{ errors[0] }}
            </div>
          </div>
        </ValidationProvider>

        <!-- Phone Number Address -->
        <ValidationProvider
          v-if="isPhoneMeeting"
          :name="$t('create_campaign.phone_number')"
          rules="required"
          vid="phoneNumber"
          mode="lazy"
          tag="div"
          v-slot="{ errors }"
        >
          <div class="form-group">
            <label for="phone-number"
              >{{ $t('create_campaign.phone_number') }} *</label
            >
            <input
              class="form-control"
              id="phone-number"
              type="text"
              v-model="meetingData.phoneNumber"
              placeholder="+12345678"
            />
            <div class="error-message" v-if="errors[0]">
              {{ errors[0] }}
            </div>
          </div>
        </ValidationProvider>

        <!-- Additional Notes -->
        <div class="form-group">
          <label for="additional-notes">{{
            $t('calendar.additional_notes')
          }}</label>
          <b-form-textarea
            id="additional-notes"
            v-model="meetingData.additionalNotes"
            rows="3"
            :placeholder="$t('calendar.placeholder.prepare_for_our_meeting')"
          ></b-form-textarea>
        </div>

        <!-- Add Guests -->
        <div class="form-group">
          <label v-if="meetingData.guests.length" class="mb-2">{{
            $t('calendar.add_guests')
          }}</label>
          <div
            v-for="(guest, index) in meetingData.guests"
            :key="index"
            class="mb-2"
          >
            <ValidationProvider
              :name="`Guest ${index + 1}`"
              :rules="{ email: !!guest }"
              tag="div"
              v-slot="{ errors }"
            >
              <div class="d-flex">
                <input
                  class="form-control mr-2"
                  v-model="meetingData.guests[index]"
                  :placeholder="$t('general.email')"
                />
                <b-button
                  variant="light"
                  @click="removeGuest(index)"
                  class="px-2"
                >
                  <i class="fas fa-times"></i>
                </b-button>
              </div>
              <div class="error-message" v-if="errors[0]">
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </div>
          <!-- <b-button
            variant="outline-secondary"
            size="sm"
            @click="addGuest"
            class="mt-2"
          >
            <i class="fas fa-plus mr-1"></i>
            {{
              meetingData.guests.length
                ? `${$('calendar.add_another')}`
                : `${$t('calendar.add_guest')}`
            }}
          </b-button> -->
        </div>

        <!-- Terms and Privacy Policy -->
        <div class="mt-4 mb-2">
          <small class="text-muted">
            <i18n path="calendar.agree_terms_and_privacy_policy" tag="span">
              <template v-slot:terms>
                <a
                  href="https://www.examiz.com/terms-and-conditions/"
                  target="_blank"
                  >{{ $t('match_candidate.terms_and_condition_link') }}
                </a>
              </template>
              <template v-slot:privacy_policy>
                <a
                  href="https://app.sharedocview.com/visualizer/6456a8efd3fa5e98fa61e066"
                  target="_blank"
                  >{{ $t('match_candidate.user_agreement') }}
                </a>
              </template>
            </i18n>
          </small>
        </div>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: '',
    },
    emailAddress: {
      type: String,
      default: '',
    },
    isPhoneMeeting: {
      type: Boolean,
      default: false,
    },
     isInPersonMeeting: {
      type: Boolean,
      default: false,
    },
    meetingAddress: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isSubmitted: false,
      isInProgress: false,
      meetingData: {
        userName: '',
        emailAddress: '',
        phoneNumber: '',
        additionalNotes: '',
        guests: [],
      },
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('update:visible', value);
      },
    },
  },
  mounted() {
    if (this.emailAddress) this.meetingData.emailAddress = this.emailAddress;
    if (this.userName) this.meetingData.userName = this.userName;
  },
  methods: {
    async handleSubmit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      // Trigger the validation
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      this.isInProgress = true;

      try {
        // Filter out empty guest emails
        const filteredGuests = this.meetingData.guests.filter(
          (guest) => guest.trim() !== ''
        );

        let location = '';
        if (this.isPhoneMeeting) {
          location = this.meetingData.phoneNumber
        } else if (this.isInPersonMeeting) {
          location = this.meetingAddress
        }

        const meetingData = {
          name: this.meetingData.userName,
          email: this.meetingData.emailAddress,
          additional_notes: this.meetingData.additionalNotes,
          guest_emails: filteredGuests,
          location,
        };

        // Emit event with meeting data
        this.$emit('confirm-meeting', meetingData);

        // Update visibility
        this.$emit('update:visible', false);

        // Also emit modal-closed event for backwards compatibility
        this.$emit('modal-closed');
      } catch (error) {
        console.error('Error confirming meeting:', error);
      } finally {
        this.isInProgress = false;
      }
    },

    onHidden() {
      // This is triggered when the modal is fully hidden
      // Important: Update the parent's state
      this.$emit('update:visible', false);

      // Also emit modal-closed event for backwards compatibility
      this.$emit('modal-closed');

      // Reset form state
      this.resetForm();
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      // Reset form data if needed
      // Uncomment these if you want to clear form data each time
      // this.meetingData = {
      //   userName: '',
      //   emailAddress: '',
      //   additionalNotes: '',
      //   guests: [],
      // };

      this.isInProgress = false;
    },

    addGuest() {
      this.meetingData.guests.push('');
    },

    removeGuest(index) {
      this.meetingData.guests.splice(index, 1);
    },

    showTerms() {
      // Implement terms display logic
      window.open('https://www.examiz.com/terms-and-conditions/', '_blank');
    },

    showPrivacyPolicy() {
      // Implement privacy policy display logic
      window.open(
        'https://app.sharedocview.com/visualizer/6456a8efd3fa5e98fa61e066',
        '_blank'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.fas {
  display: inline-block;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

// Use deep selector for modal styling
::v-deep .dark-modal {
  & > .modal-dialog > .modal-content {
    background-color: #1a1a1a;
    color: #ffffff;
  }
}

::v-deep .dark-modal-header {
  background-color: #1a1a1a !important;
  border-bottom-color: #333333 !important;

  h5 {
    color: #fff !important;
  }

  .close {
    color: #ffffff;

    &:hover {
      color: #f8f9fa;
    }
  }
}

::v-deep .dark-modal-body {
  background-color: #1a1a1a;
  color: #ffffff;

  input::placeholder {
    color: #fff;
  }

  textarea::placeholder {
    color: #fff;
  }

  label {
    color: #fff !important;
  }

  .form-control {
    background-color: #2c2c2c;
    border-color: #444444;
    color: #ffffff !important;

    &:focus {
      background-color: #2c2c2c;
      border-color: #666666;
      color: #ffffff;
    }
  }

  .btn-outline-secondary {
    color: #adb5bd;
    border-color: #495057;

    &:hover {
      background-color: #343a40;
      color: #f8f9fa;
    }
  }
}

::v-deep .dark-modal-footer {
  background-color: #1a1a1a !important;
  border-top-color: #333333 !important;
  color: #ffffff;
}
</style>