<template>
  <div>
    <h2 class="report__section__title toggle__trigger mt-30 mb-5">
      <span class="toggle__trigger__text text-uppercase">{{
        $t('report.table_of_contents')
      }}</span>
    </h2>
    <div
      class="report__content__text"
      v-for="(item, idx) in computedContents"
      :key="idx"
    >
      <div class="row">
        <div class="col-7">
          <h6>
            {{ item.name }}
          </h6>
        </div>
        <div class="col-5">
          <span class="ml-3">{{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { method } from 'lodash';

export default {
  data() {
    return {
      loaded: false,
      computedContents: [],
      fields: [{ key: 'name' }, { key: 'value' }],
    };
  },
  computed: {
    contents() {
      if (this.loaded) {
        return [
          {
            name: this.$t('report.your_strengths'),
            value: 4,
          },
          {
            name: this.$t('report.your_core_competencies'),
            value: 5,
          },
          {
            name: this.$t('report.your_personality_strengths'),
            value: this.getValue(this.$t('report.your_personality_strengths')),
          },
          {
            name: this.$t('report.managerial_strengths'),
            value: this.getValue(this.$t('report.managerial_strengths')),
          },
          {
            name: this.$t('report.your_potential'),
            value: this.getValue(this.$t('report.your_potential')),
          },
          {
            name: this.$t('report.your_personality_type'),
            value: this.getValue(this.$t('report.your_personality_type')),
          },
          {
            name: this.$t('report.interview_preparation'),
            value: this.getValue(this.$t('report.your_interview_preparation')),
          },
        ];
      }
      return [];
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loaded = true;
      this.computedContents = this.contents;
    });
  },
  methods: {
    getValue(title) {
      const pages = document.querySelectorAll(
        '.page-break-before-always, .page_campaign_cover'
      );
      let index = Array.from(pages).findIndex((page) => {
        const heading = page.querySelector('h1, h2');
        return heading && heading.textContent.trim().includes(title);
      });

      return index + 1;
    },
  },
};
</script>


