<template>
  <div class="calendar-layout">
    <div v-if="$route.meta.showBackBtn" class="p-4">
      <b-link :href="backUrl" style="color: #fff">
        <b-icon icon="chevron-left"></b-icon>
        {{ $t('calendar.back_to_calendar') }}
      </b-link>
    </div>

    <router-view />

    <div class="company-logo">
      <img :src="eventCompanyLogoSrc" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      eventCompanyLogoSrc: 'calendar/eventCompanyLogoSrc',
    }),
    backUrl() {
      return `${document.location.origin}/book/${this.$route.params.eventId}`;
    },
  },
};
</script>

<style scoped lang="scss">
.calendar-layout {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  color: #fff;
  background-color: #171717;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;

  .company-logo {
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 20px;

    img {
      max-height: 90px;
    }
  }
}

@media (max-width: 480px) {
  .calendar-layout {
    background: #1a1a1a;
  }
}
</style>
