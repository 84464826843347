<template>
  <candidate-details-table
    :report_title="$t('report.potential_score')"
    :items="potential_attrs"
    :fields="fields"
    :icon="'mdi mdi-finance'"
    :candidate_score="candidate.high_potential_score"
    :highScore="report.bestPotentialScore"
  ></candidate-details-table>
</template>

<script>
import { orderBy } from 'lodash';
import { mapGetters } from 'vuex';
import { calculateTwoSideProgressBarValue } from '@/common/utils';
import CandidateDetailsTable from '@/components/CandidateDetailsTable';

export default {
  props: {
    candidate: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  components: {
    CandidateDetailsTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('create_campaign.traits'),
          thClass: 'attribute__name',
        },
        {
          key: 'avg',
          thClass: 'attribute__avg',
        },
        {
          key: 'progress',
          thClass: '',
        },
        {
          key: 'score',
          thClass: 'attribute__score',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('report', ['reportAvgValues', 'report']),
    potential_attrs() {
      if (!this.candidate.high_potential_attrs) return [];

      const values = this.candidate.high_potential_attrs.map((item) => {
        const progressBarWidth = calculateTwoSideProgressBarValue(
          item.score,
          this.reportAvgValues[item.attribute]
        );

        return {
          progress: progressBarWidth,
          name: item.name,
          score: item.score,
          avg: this.reportAvgValues[item.attribute],
        };
      });

      return orderBy(values, ['name'], ['asc']);
    },
  },
};
</script>
