import { mapGetters } from "vuex";
import { calculateTwoSideProgressBarValue } from '@/common/utils';


const ExitReportHelpersMixin = {
  data() {
    return {
      max: 100,
      exitChartColumns: [
        {
          key: 'label',
          label: '',
          tdClass: 'attribute__name',
        },
        {
          key: 'value',
          label: '',
          tdClass: 'attribute__score',
        },
      ],
      complianceRisksColumns: [
        {
          key: 'key',
          label: '',
          tdClass: 'attribute__name',
        },
        {
          key: 'value',
          label: '',
          tdClass: 'attribute__score',
        },
        {
          key: 'flags',
          label: '',
          tdClass: 'attribute__flags',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('global', ['exitQuestions']),
    ...mapGetters('exit', ['campaign']),
    complianceRisksTranslations() {
      return {
        exit_13_2_1: this.$t(`exit.attributes.exit_13_2_1`),
        exit_13_2_2: this.$t(`exit.attributes.exit_13_2_2`),
        exit_13_2_3: this.$t(`exit.attributes.exit_13_2_3`),
        exit_13_2_4: this.$t(`exit.attributes.exit_13_2_4`),
      }
    },
    allQuestions() {
      return {
        ...this.exitQuestions,
        ...this.complianceRisksTranslations
      }
    },
    reasonsLeaving() {
      return this.prepareReportData(this.report.reasons_for_leaving, 5);
    },
    complianceRisks() {
      return this.prepareReportData(this.report.compliance_risks, 5);
    },
    detailedAnalytics() {
      return Object.keys(this.report.detailed_analytics)
        .map((key) => ({
          label: this.getKeyName(key),
          values: this.checkValidKey(key) ? this.prepareReportData(this.report.detailed_analytics[key]) : {},
          sort: (this.reasonsLeaving.find(item => item.key === key) || {}).value,
        }))
        .sort((a, b) => b.sort - a.sort)
    },
  },
  methods: {
    getKeyName(key) {
      return this.allQuestions[key].name
    },
    checkValidKey(key) {
      // Original task for this is PD-960
      // It cause bug described in the task PD-1138
      // For now we are going to disable this check
      // TODO: Decide if this is needed
      // return !key.includes('exit_main_1' || 'exit_main_2')
      return true;
    },
    prepareReportData(object, maxItems) {
      return Object.keys(object)
        .map((key) => ({
          key: key,
          value: object[key],
          label: this.getKeyName(key) || this.complianceRisksTranslations[key],
        }))
        .filter(item => typeof item.value === 'object' ? item.value.value >= 0 : item.value >= 5)
        .sort((a, b) => b.value - a.value)
        .slice(0, maxItems);
    },
    calculateProgressBarLeftValue(value) {
      const calc = calculateTwoSideProgressBarValue(value);
      // console.log(calc < 0 ? Math.abs(calc) : 0)
      return calc < 0 ? Math.abs(calc) : 0;
    },
    calculateProgressBarRightValue(value) {
      const calc = calculateTwoSideProgressBarValue(value);
      return calc >= 0 ? calc : 0;
    },
  }
};

export default ExitReportHelpersMixin;