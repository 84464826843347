import axios from 'axios';

import i18n from '../../../i18n';
import { RECRUITER_TYPE, JOB_SEEKER_TYPE, RECRUITERS_ROLES } from '@/common/constants'

const CHANGE_LANGUAGE = (state, language) => {
  const lang = language || localStorage.getItem('language') || 'en';
  state.currentLanguage = lang;
  localStorage.setItem('language', lang);
  i18n.locale = lang;
  axios.defaults.headers.common['Locale'] = lang;
};

const AUTH_USER_COMPANY = (state, companyData) => {
  if (companyData.logo) {
    state.companyLogoUrl = companyData.logo.url;
  }
  if (companyData.name) {
    state.currentUserCompanyName = companyData.name;
    localStorage.setItem('company-name', companyData.name);
  }

  state.companyInfo = companyData;
};

const AUTH_USER = (state, userData) => {
  let role = localStorage.getItem('role');
  if (role) {
    state.currentUserRole = role
  } else {
    role = userData.companies && userData.companies.length > 0 ? RECRUITER_TYPE : JOB_SEEKER_TYPE;
    localStorage.setItem('role', role);
    state.currentUserRole = role;
  }

  if (userData.companies && userData.companies.length > 0) {
    state.currentUserCompanies = userData.companies;

    const currentCompanyId = localStorage.getItem('company-id');
    const currentCompany = userData.companies.find(c => c.id == currentCompanyId);

    if (currentCompany) {
      state.currentUserCompanyId = currentCompany.id
      state.isCompanyAdmin = currentCompany.role === RECRUITERS_ROLES.OWNER || RECRUITERS_ROLES.ADMINISTRATOR
    } else {
      state.currentUserCompanyId = userData.companies[0].id
    }

    localStorage.setItem('company-id', state.currentUserCompanyId)
  }

  localStorage.setItem('language', userData.lang);
  localStorage.setItem('user-id', userData.user_id);
  localStorage.setItem('user-token', userData.token);
  localStorage.setItem('first-name', userData.first_name);
  localStorage.setItem('last-name', userData.last_name);
  localStorage.setItem('email', userData.email);
  localStorage.setItem('location', userData.location);

  state.currentUserEmail = userData.email;
  state.currentUserFirstName = userData.first_name;
  state.currentUserLastName = userData.last_name;
  state.currentUserCountry = userData.country;
  state.userToken = userData.token;
  state.currentUserId = userData.user_id;
  state.selftestSurveyPassed = userData.survey_passed;
  state.currentUserLocation = userData.location;
  state.currentLanguage = userData.lang;

  i18n.locale = userData.lang;

  axios.defaults.headers.common['X-Examiz-Auth'] = userData.token;
  axios.defaults.headers.common['Locale'] = userData.lang;
};

const AUTH_ADMIN = adminData => {
  axios.defaults.headers.common['X-Examiz-Admin-Company'] = adminData;
};

const AUTH_REFERENCE = (state, referenceData) => {
  state.userToken = referenceData.token;
  localStorage.setItem('user-token', referenceData.token);
  localStorage.setItem('title', referenceData.title);
  localStorage.setItem('firstName', referenceData.firstName);
  localStorage.setItem('lastName', referenceData.lastName);
};

const LOGOUT_REFERENCE = () => {
  localStorage.removeItem('user-id');
  localStorage.removeItem('role');
  localStorage.removeItem('user-token');
  localStorage.removeItem('title');
  localStorage.removeItem('lastName');
  localStorage.removeItem('firstName');
};

const AUTH_SELF_TEST = (state, data) => {
  state.userToken = data.token;
  localStorage.setItem('user-token', data.token);
};

const LOGOUT = state => {
  state.currentUserEmail = null;
  state.currentUserFirstName = null;
  state.currentUserLastName = null;
  state.currentUserCompanyId = null;
  state.currentUserCountry = null;
  state.userToken = null;
  state.currentUserId = null;
  state.currentUserRole = '';
  localStorage.removeItem('role');
  localStorage.removeItem('user-token');
  localStorage.removeItem('token');
  localStorage.removeItem('user-id');
  localStorage.removeItem('first-name');
  localStorage.removeItem('last-name');
  localStorage.removeItem('email');
  localStorage.removeItem('company-name');
  localStorage.removeItem('company-id');
  axios.defaults.headers.common['X-Examiz-Auth'] = null;
  axios.defaults.headers.common['X-Examiz-Admin-Company'] = null;
  delete axios.defaults.headers.common['X-Examiz-Auth'];
  delete axios.defaults.headers.common['X-Examiz-Admin-Company'];
};

const SET_AUTH_HEADER = state => {
  if (state.userToken && state.userToken.length > 0) {
    axios.defaults.headers.common['X-Examiz-Auth'] =
      'Bearer ' + state.userToken;
  } else {
    axios.defaults.headers.common['X-Examiz-Auth'] = null;
    delete axios.defaults.headers.common['X-Examiz-Auth'];
  }
};

const POPULATE_ATTRIBUTES = state => {
  const attributes = {};
  ['values', 'competencies', 'traits'].forEach(key => {
    attributes[key] = window.attributes[key].reduce(
      (accumulator, currentValue) => {
        accumulator[currentValue.value] = accumulator[currentValue.value] || {
          attribute: currentValue.value,
          value: 50
        };
        accumulator[currentValue.value][`name_${currentValue.language}`] =
          currentValue.text;
        return accumulator;
      },
      {}
    );
  });
  state.attributes = attributes;
};

const POPULATE_PERMANENT_ATTRIBUTES = state => {
  const attributes = [];

  window.attributes.permanent.forEach((item, i) => {
    attributes.push({ key: item.key, name: item.name, description: item.description })
  });

  attributes.sort((a, b) => a.name.localeCompare(b.name))

  state.permanentAttributes = attributes;
};

const POPULATE_TALENTS_ATTRIBUTES = state => {
  const attributes = {};
  [
    'values',
    'competencies',
    'traits',
    'performance',
    'high_performance',
    'high_potential',
    'rock_star'
  ].forEach(key => {
    attributes[key] = window.talents_attributes[key].reduce(
      (accumulator, currentValue) => {
        accumulator[currentValue.value] = accumulator[currentValue.value] || {
          attribute: currentValue.value
        };
        accumulator[currentValue.value][`name_${currentValue.language}`] =
          currentValue.text;
        return accumulator;
      },
      {}
    );
  });
  state.talents_attributes = attributes;
};

const SET_REDIRECT_TO = (state, path) => {
  state.redirectTo = path;
  if (path) {
    localStorage.setItem('redirect-to', path);
  } else {
    localStorage.removeItem('redirect-to');
  }
};

const SHOW_SPINNER = (state, options = {}) => {
  state.showSpinner = true;
  state.spinnerOptions = options;
}

const HIDE_SPINNER = (state) => {
  state.showSpinner = false;
}

// Config
const SET_JOB_TYPES = (state, types = []) => {
  state.jobTypes = types;
}

const SET_TIME_ZONES = (state, timeZones = []) => {
  state.timeZones = timeZones;
}

const SET_TITLES = (state, titles = {}) => {
  state.titles = titles;
}

const SET_AVAILABLE_LANGUAGES = (state, availableLanguages = []) => {
  const languages = [];
  Object.keys(availableLanguages).forEach(key => {
    languages.push({
      value: key,
      text: availableLanguages[key]
    })
  })
  state.availableLanguages = languages;
}

const SET_AVAILABLE_COUNTRIES = (state, availableCountries = {}) => {
  const countries = [];
  Object.keys(availableCountries).forEach(key => {
    countries.push({
      value: key,
      text: availableCountries[key]
    })
  })
  state.availableCountries = countries;
  state.availableCountriesWithKey = availableCountries
}

const SET_LANGUAGE_PROFICIENCY_LEVELS = (state, levels = []) => {
  state.languageProficiencyLevels = levels
    .map(item => {
      return {
        text: item.title,
        value: item.id
      }
    })
    .sort((a, b) => a.value - b.value);
}

const SET_EDUCATION_GENERAL_AREAS = (state, areas = []) => {
  state.educationGeneralAreas = areas.map(item => {
    return {
      text: item.title,
      value: item.id
    }
  });
}

const SET_JOB_AREAS_OLD = (state, areas = []) => {
  state.jobAreasOld = areas.map(item => {
    return {
      text: item.title,
      value: item.id,
      jobAreaSpecializations: item.job_area_specializations
    }
  });
}

const SET_YEARS_OF_EXPERIENCE = (state, years) => {
  state.yearsOfExperience = years.map(item => {
    return {
      text: item.title,
      value: item.id,
    }
  })
}

const SET_SENIORITY_LEVEL = (state, levels) => {
  state.senorityLevel = levels.map(item => {
    return {
      text: item.title,
      value: item.id,
    }
  })
}

const SET_EDUCATION_AREAS = (state, areas) => {
  state.educationAreas = areas.map(item => {
    return {
      text: item.title,
      value: item.id,
    }
  });
}

const SET_EDUCATION_GROUPS = (state, groups) => {
  state.educationGroups = groups.map(item => {
    return {
      text: item.title,
      value: item.id,
    }
  });
}

const SET_EDUCATION_SPECIALIZATIONS = (state, specializations) => {
  state.educationSpecializations = specializations.map(item => {
    return {
      text: item.title,
      value: item.id,
    }
  });
}

const SET_JOB_AREAS = (state, areas) => {
  state.jobAreas = areas.map(item => {
    return {
      text: item.title,
      value: item.id,
    }
  });
}

const SET_JOB_GROUPS = (state, groups) => {
  state.jobGroups = groups.map(item => {
    return {
      text: item.title,
      value: item.id,
    }
  });
}

const SET_JOB_SPECIALIZATIONS = (state, specializations) => {
  state.jobSpecializations = specializations.map(item => {
    return {
      text: item.title,
      value: item.id,
    }
  });
}

const SET_BULK_HIRING_TYPES = (state, types) => {
  state.bulkHiringTypes = types;
}

const SET_BULK_HIRING_PROFILES = (state, profiles) => {
  state.bulkHiringProfiles = profiles;
}

const SET_EXIT_QUESTIONS = (state, questions) => {
  state.exitQuestions = questions
}

// Calendar
const SET_USER_CALENDAR = (state, calendar) => {
  state.userCalendar = calendar;
}

const SET_CALENDAR_LOADING = (state, isLoading) => {
  state.calendarLoading = isLoading;
}

const SET_CALENDAR_ERROR = (state, error) => {
  state.calendarError = error;
}

const CLEAR_CALENDAR = (state) => {
  state.userCalendar = null;
}

export default {
  AUTH_USER,
  AUTH_ADMIN,
  LOGOUT,
  SET_AUTH_HEADER,
  CHANGE_LANGUAGE,
  AUTH_REFERENCE,
  LOGOUT_REFERENCE,
  AUTH_SELF_TEST,
  AUTH_USER_COMPANY,
  POPULATE_ATTRIBUTES,
  POPULATE_PERMANENT_ATTRIBUTES,
  POPULATE_TALENTS_ATTRIBUTES,
  SET_REDIRECT_TO,
  SHOW_SPINNER,
  HIDE_SPINNER,
  // Config
  SET_JOB_TYPES,
  SET_TIME_ZONES,
  SET_TITLES,
  SET_AVAILABLE_LANGUAGES,
  SET_AVAILABLE_COUNTRIES,
  SET_LANGUAGE_PROFICIENCY_LEVELS,
  SET_EDUCATION_GENERAL_AREAS, // deprecated
  SET_JOB_AREAS_OLD, // deprecated
  SET_YEARS_OF_EXPERIENCE,
  SET_SENIORITY_LEVEL,
  SET_EDUCATION_AREAS,
  SET_EDUCATION_GROUPS,
  SET_EDUCATION_SPECIALIZATIONS,
  SET_JOB_AREAS,
  SET_JOB_GROUPS,
  SET_JOB_SPECIALIZATIONS,
  SET_BULK_HIRING_TYPES,
  SET_BULK_HIRING_PROFILES,
  SET_EXIT_QUESTIONS,
  // Calendar
  SET_USER_CALENDAR,
  SET_CALENDAR_LOADING,
  SET_CALENDAR_ERROR,
  CLEAR_CALENDAR,
};
