<template>
  <div class="confirmation-container d-flex">
    <div class="confirmation">
      <div v-if="eventCanceled" class="confirmation-icon canceled-icon">
        <b-icon icon="x" variant="success" />
      </div>
      <div v-else class="confirmation-icon">
        <b-icon icon="check" variant="success" />
      </div>

      <h3 v-if="eventCanceled">{{ $t('calendar.meeting_is_canceled') }}</h3>
      <h3 v-if="!eventCanceled && !$route.meta.reschedule">{{ $t('calendar.meeting_is_scheduled') }}</h3>
      <h3 v-if="!eventCanceled && $route.meta.reschedule">{{ $t('calendar.meeting_is_rescheduled') }}</h3>

      <p>
        {{ $t('calendar.we_sent_details_to_everyone') }}
      </p>

      <hr />

      <div class="info">
        <div class="info-row">
          <div class="info-title">{{ $t('calendar.what') }}</div>
          <div class="info-content">
            {{ bookedEvent.event.name }}
          </div>
        </div>

        <div class="info-row">
          <div class="info-title">{{ $t('calendar.when') }}</div>
          <div
            class="info-content"
            :style="{ 'text-decoration': eventCanceled ? 'line-through' : '' }"
          >
            {{ new Date(bookedEvent.scheduled_at).toLocaleString() }}
            ({{ timeZone || localTimeZone }})
          </div>
        </div>

        <div class="info-row">
          <div class="info-title">{{ $t('calendar.who') }}</div>
          <div class="info-content">
            <p>
              {{ bookedEvent.host.name }}
              <b-badge>{{ $t('calendar.host') }}</b-badge
              ><br />
              {{ bookedEvent.host.email }}
            </p>
            <p>
              {{ eventCandidate.name }} <br />
              {{ eventCandidate.email }}
            </p>
          </div>
        </div>

        <div class="info-row">
          <div class="info-title">{{ $t('calendar.where') }}</div>
          <div v-if="event.event_type === 'video'" class="info-content">
            <a :href="bookedEvent.video_meeting_link" target="_blank">{{
              $t('calendar.video_call')
            }}</a>
          </div>
          <div v-if="event.event_type === 'phone'" class="info-content">
            {{ $t('calendar.via_phone') }}:
            {{ event.location_details.phone_number }}
          </div>

          <div v-if="event.event_type === 'in_person'" class="info-content">
            {{ $t('calendar.in_person') }}:
            {{ event.location_details.location_address }}
          </div>
        </div>
      </div>

      <hr />

      <div v-if="$route.meta.cancel && !eventCanceled" class="w-100">
        <div class="mb-3">{{ $t('calendar.reason_for_cancellation') }}</div>
        <b-form-textarea
          v-model="cancelingNote"
          :placeholder="$t('calendar.placeholder.why_are_you_canceling')"
          rows="3"
          max-rows="6"
          class="mb-1"
          style="background: #1a1a1a; border-color: #4a4a4a"
        ></b-form-textarea>
        <div style="font-size: 1.3rem; color: #9a9a9a" class="mb-3">
          <b-icon icon="info-circle" />
          {{ $t('calendar.cancellation_reason_will_be_shared') }}
        </div>
        <div class="float-right">
          <b-button
            variant="danger"
            :disabled="!cancelingNote.length || showSpinner"
            class="d-flex align-items-center justify-content-center"
            style="min-width: 80px"
            @click="cancelBookedEvent"
          >
            <b-spinner
              v-if="showSpinner"
              style="left: auto; top: auto; font-size: 2rem"
            ></b-spinner>
            <span v-if="!showSpinner">{{ $t('general.cancel') }}</span>
          </b-button>
        </div>
      </div>

      <div v-if="!$route.meta.cancel">
        {{ $t('calendar.need_to_make_a_change') }}
        <b-link :href="rescheduleLink">{{ $t('calendar.reschedule') }}</b-link>
        {{ $t('general.or') }}
        <b-link :href="cancelLink">{{ $t('general.cancel') }}</b-link>
      </div>

      <hr v-if="!$route.meta.cancel" />

      <div v-if="!$route.meta.cancel" class="calendar-buttons">
        <div class="calendar-text">{{ $t('calendar.add_to_calendar') }}</div>

        <div class="calendar-links">
          <b-button variant="link" class="calendar-btn" @click="openCalendarLink(googleCalendarLink)">
            <b-icon icon="google" too />
            <!-- <span class="calendar-label">Google</span> -->
          </b-button>

          <b-button variant="link" class="calendar-btn" @click="openCalendarLink(office365Link)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-microsoft"
              viewBox="0 0 16 16"
            >
              <path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z"/>
            </svg>
            <!-- <span class="calendar-label">Office 365</span> -->
          </b-button>

          <b-button variant="link" class="calendar-btn" @click="openCalendarLink(outlookWebLink)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-envelope"
              viewBox="0 0 16 16"
            >
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
            </svg>
            <!-- <span class="calendar-label">Outlook</span> -->
          </b-button>

          <b-button variant="link" class="calendar-btn" @click="openCalendarLink(iCalLink)">
            <b-icon icon="calendar-event" />
            <!-- <span class="calendar-label">iCal</span> -->
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    timeZone: {
      type: String,
    },
  },
  data() {
    return {
      cancelingNote: '',
      showSpinner: false,
      eventCanceled: false,
    };
  },
  computed: {
    ...mapGetters({
      bookedEvent: 'calendar/bookedEvent',
      event: 'calendar/event',
      eventCandidate: 'calendar/eventCandidate',
    }),
    localTimeZone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    location() {
      if (this.event.event_type === 'phone') {
        return '';
      }

      if (this.event.event_type === 'in_person') {
        return '';
      }

      return this.bookedEvent.video_meeting_link;
    },
    rescheduleLink() {
      return `${document.location.origin}/book/${this.event.id}/reschedule?e=${this.bookedEvent.id}&c=${this.eventCandidate.id}`;
    },
    cancelLink() {
      return `${document.location.origin}/book/${this.event.id}/cancel?e=${this.bookedEvent.id}&c=${this.eventCandidate.id}`;
    },
    // Calendar links
    googleCalendarLink() {
      return this.generateGoogleCalendarLink();
    },
    outlookWebLink() {
      return this.generateOutlookWebLink();
    },
    office365Link() {
      return this.generateOffice365Link();
    },
    iCalLink() {
      return this.generateICalLink();
    }
  },
  methods: {
    cancelBookedEvent() {
      this.showSpinner = true;

      this.$store
        .dispatch('calendar/cancelBookedEvent', {
          eventId: this.event.id,
          bookedEventId: this.bookedEvent.id,
          payload: {
            cancellation_reason: this.cancelingNote,
          },
        })
        .then(() => {
          this.eventCanceled = true;
        })
        .catch(() => {
          // TODO: add error message
          this.showSpinner = false;
        });
    },

    // Calendar link generation methods
    formatDateForCalendar(date) {
      const d = new Date(date);
      return d.toISOString().replace(/-|:|\.\d+/g, '');
    },

    generateGoogleCalendarLink() {
      if (!this.bookedEvent || !this.bookedEvent.scheduled_at) return '#';

      const startTime = this.formatDateForCalendar(this.bookedEvent.scheduled_at);
      // Assuming event duration is in minutes, default to 30 if not provided
      const duration = this.event.duration || 30;
      const endTime = this.formatDateForCalendar(
        new Date(new Date(this.bookedEvent.scheduled_at).getTime() + duration * 60000)
      );

      const params = new URLSearchParams({
        dates: `${startTime}/${endTime}`,
        text: `${this.bookedEvent.event.name} between ${this.bookedEvent.host.name} and ${this.eventCandidate.name}`,
        details: this.bookedEvent.description || '',
        location: this.bookedEvent.video_meeting_link || '',
      });

      return `https://calendar.google.com/calendar/r/eventedit?${params.toString()}`;
    },

    generateOutlookWebLink() {
      if (!this.bookedEvent || !this.bookedEvent.scheduled_at) return '#';

      const startTime = new Date(this.bookedEvent.scheduled_at).toISOString();
      const duration = this.event.duration || 30;
      const endTime = new Date(
        new Date(this.bookedEvent.scheduled_at).getTime() + duration * 60000
      ).toISOString();

      const params = new URLSearchParams({
        path: '/calendar/action/compose',
        rru: 'addevent',
        startdt: startTime,
        enddt: endTime,
        subject: `${this.bookedEvent.event.name} between ${this.bookedEvent.host.name} and ${this.eventCandidate.name}`,
        body: this.bookedEvent.description || '',
        location: this.bookedEvent.video_meeting_link || '',
      });

      return `https://outlook.live.com/calendar/0/deeplink/compose?${params.toString()}`;
    },

    generateOffice365Link() {
      if (!this.bookedEvent || !this.bookedEvent.scheduled_at) return '#';

      const startTime = new Date(this.bookedEvent.scheduled_at).toISOString();
      const duration = this.event.duration || 30;
      const endTime = new Date(
        new Date(this.bookedEvent.scheduled_at).getTime() + duration * 60000
      ).toISOString();

      const params = new URLSearchParams({
        path: '/calendar/action/compose',
        rru: 'addevent',
        startdt: startTime,
        enddt: endTime,
        subject: `${this.bookedEvent.event.name} between ${this.bookedEvent.host.name} and ${this.eventCandidate.name}`,
        body: this.bookedEvent.description || '',
        location: this.bookedEvent.video_meeting_link || '',
      });

      return `https://outlook.office.com/calendar/0/deeplink/compose?${params.toString()}`;
    },

    generateICalLink() {
      if (!this.bookedEvent || !this.bookedEvent.scheduled_at) return '#';

      const formatICalDate = (date) => {
        return this.formatDateForCalendar(date).slice(0, -1) + 'Z'; // Format for iCal
      };

      const startTime = formatICalDate(this.bookedEvent.scheduled_at);
      const eventUid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      const duration = this.event.duration || 30;
      const durationFormatted = `PT${duration}M`; // Format: PT30M for 30 minutes

      const icalContent = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'CALSCALE:GREGORIAN',
        'PRODID:calendar-integration',
        'METHOD:PUBLISH',
        'X-PUBLISHED-TTL:PT1H',
        'BEGIN:VEVENT',
        `UID:${eventUid}`,
        `SUMMARY:${this.bookedEvent.event.name} between ${this.bookedEvent.host.name} and ${this.eventCandidate.name}`,
        `DTSTAMP:${formatICalDate(new Date())}`,
        `DTSTART:${startTime}`,
        `LOCATION:${this.bookedEvent.video_meeting_link || ''}`,
        `DURATION:${durationFormatted}`,
        'END:VEVENT',
        'END:VCALENDAR'
      ].join('\r\n');

      // Encode the iCal content
      return `data:text/calendar,${encodeURIComponent(icalContent)}`;
    },

    openCalendarLink(url) {
      window.open(url, '_blank');
    }
  },
};
</script>

<style lang="scss" scoped>
.confirmation-container {
  width: 100%;
  max-width: 640px;
  margin: auto;
  font-size: 1.6rem;
  padding: 0 15px;

  textarea {
    color: #ffff;
  }

  textarea::placeholder {
    color: #fff;
  }

  .spinner-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .confirmation {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #1a1a1a;
    border: 1px solid #262626;
    border-radius: 10px;
    padding: 20px;

    .confirmation-icon {
      background: #285231;
      padding: 10px;
      width: 60px;
      height: 60px;
      border-radius: 50px;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        fill: #4bde80;
        font-size: 40px;
      }
    }

    .canceled-icon {
      background: #522828;
      svg {
        fill: #de4b4b;
        font-size: 40px;
      }
    }

    .info {
      width: 100%;

      .info-row {
        display: flex;
        margin-bottom: 24px;
        flex-direction: row;

        @media (max-width: 576px) {
          flex-direction: column;
          margin-bottom: 16px;
        }

        .info-title {
          min-width: 4rem;
          font-weight: bold;
          margin-right: 2rem;
        }

        .info-content {
          flex: 1;
        }
      }
    }

    .link-button {
      font-size: 1.6rem;
      font-weight: normal;
      text-transform: none;
      padding: 0;
      margin-top: -2px;
    }

    .calendar-buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .calendar-text {
        margin-bottom: 8px;
      }

      .calendar-links {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .calendar-btn {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          background: #2a2a2a;
          border-radius: 4px;
          color: #fff;
          transition: background-color 0.2s;

          &:hover {
            background: #3a3a3a;
            text-decoration: none;
          }

          // svg {
          //   margin-right: 8px;
          // }
        }
      }
    }

    hr {
      width: 100%;
      border-top: 1px solid #4a4a4a !important;
      margin: 16px 0;
    }
  }
}

@media (max-width: 768px) {
  .confirmation-container {
    font-size: 1.4rem;
    background: #1a1a1a;

    .confirmation {
      border: none;
    }
  }
}

@media (max-width: 576px) {
  .confirmation-container {
    padding: 0 10px;
  }
}
</style>