import ApiCalendar from '../../../api/calendar';

// Fetch calendars available for company based on enabled recruiters calendars
const getCompanyCalendars = ({ rootGetters, commit }) => {
  const companyId = rootGetters['global/currentUserCompanyId']

  return ApiCalendar.getCompanyCalendars(companyId)
    .then((res) => {
      commit('SET_COMPANY_CALENDARS', res.data.recruiters)
    })
}

const createEvent = ({ rootGetters, commit }, { campaignId, event }) => {
  const companyId = rootGetters['global/currentUserCompanyId']

  const payload = {
    calendar_event: {
      name: event.name,
      event_type: event.type,
      duration_minutes: event.durationMinutes,
      description: event.description,
      location_details: {
        location_address: event.address,
        phone_number: event.phone_number
      },
      host_ids: event.recruiters
    }
  }

  return ApiCalendar.createEvent(companyId, campaignId, payload);
}

const getCampaignEvents = ({ rootGetters, commit }, { campaignId }) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  return ApiCalendar.getCampaignEvents(companyId, campaignId)
    .then((res) => {
      commit('SET_CAMPAIGN_EVENTS', res.data.events)
    })
}

const sendInterviewInvitations = ({ rootGetters }, { campaignId, eventId, candidateIds }) => {
  const companyId = rootGetters['global/currentUserCompanyId']
  const payload = {
    candidate_ids: candidateIds
  }

  return ApiCalendar.sendInterviewInvitations(companyId, campaignId, eventId, payload)
    .then((res) => {
      console.log('res', res)
    })
}

const getEvent = ({ commit }, { eventId, candidateId }) => {
  return ApiCalendar.getEvent(eventId, candidateId)
    .then((res) => {
      commit('SET_EVENT_DATA', res.data);
    })
}

const getAvailableSlots = ({ commit }, { eventId, query = {} }) => {
  return ApiCalendar.getAvailableSlots(eventId, query)
    .then((res) => {
      console.log('res', res)
      commit('SET_AVAILABLE_SLOTS', res.data.available_slots)
    })
}

const bookMeeting = ({ commit }, { eventId, payload }) => {
  return ApiCalendar.bookMeeting(eventId, payload)
    .then((res) => {
      commit('SET_BOOKED_EVENT', res.data.booked_event);
    })
}

const getBookedEvent = ({ commit }, { eventId, bookedEventId }) => {
  return ApiCalendar.getBookedEvent(eventId, bookedEventId)
    .then((res) => {
      commit('SET_BOOKED_EVENT', res.data.booked_event);
    })
}

const rescheduleBookedEvent = ({ commit }, { eventId, bookedEventId, payload }) => {
  return ApiCalendar.rescheduleBookedEvent(eventId, bookedEventId, payload)
    .then((res) => {
      commit('SET_BOOKED_EVENT', res.data.booked_event);
    })
}

const cancelBookedEvent = ({ commit }, { eventId, bookedEventId, payload }) => {
  return ApiCalendar.cancelBookedEvent(eventId, bookedEventId, payload);
}

export default {
  getCompanyCalendars,
  createEvent,
  getCampaignEvents,
  sendInterviewInvitations,
  getEvent,
  getAvailableSlots,
  bookMeeting,
  getBookedEvent,
  rescheduleBookedEvent,
  cancelBookedEvent,
};
