<template>
  <div>
    <section
      class="page-break-before-always page-break-inside-avoid page_campaign_summary"
    >
      <header-title :title="title" :variant="variant"></header-title>
      <div class="row mt-10">
        <div class="col-6"><slot></slot></div>
        <div class="col-6" :class="{ 'mt-5': potential }">
          <img :src="img" :alt="title" />
        </div>
      </div>
      <div class="mt-20">
        <h6 class="personality_title">{{ subTitle || title }}</h6>
        <p class="personality_description">
          {{ description }}
        </p>
      </div>
      <br />
    </section>
    <personality-types-extensively
      :title="title"
      :personalityType="firstPage"
      :talentsAttr="talentsAttr"
      :attrDescription="attrDescription"
      :currentLang="currentLang"
      :attribute-name="attributeName"
      :variant="variant"
      :isTitle="isTitle"
    ></personality-types-extensively>
    <personality-types-extensively
      v-if="secondPage.length"
      :title="title"
      :personalityType="secondPage"
      :talentsAttr="talentsAttr"
      :attrDescription="attrDescription"
      :currentLang="currentLang"
      :attribute-name="attributeName"
      :variant="variant"
      :isTitle="isTitle"
    ></personality-types-extensively>
  </div>
</template>

<script>
import { orderBy } from 'lodash';
import { SELEFTEST_VALUES_MIN_VALUE } from '@/common/constants';
import PersonalityTypesExtensively from './PersonalityTypesExtensively';
import HeaderTitle from './HeaderTitle';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    competencies: {
      type: Array,
      required: true,
      default: () => {},
    },
    talentsAttr: {
      type: Object,
      required: true,
      default: () => {},
    },
    attrDescription: {
      type: Object,
      required: true,
      default: () => {},
    },
    currentLang: {
      type: String,
      required: true,
      default: 'en',
    },
    attributeName: {
      type: String,
      required: true,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    top: {
      type: Number,
      default: 5,
    },
    potential: {
      type: Boolean,
      default: false,
    },
    isTitle: {
      type: Boolean,
      default: true,
    },
    img: {
      type: String,
      required: true,
      default: '',
    },
  },
  components: {
    PersonalityTypesExtensively,
    HeaderTitle,
  },
  data() {
    return {};
  },
  computed: {
    filterCompetencies() {
      if (!this.competencies) return [];

      let values = this.competencies.map((item) => {
        return {
          key: item.key,
          value: item.value,
        };
      });
      values = values
        .filter((item) => item.value >= SELEFTEST_VALUES_MIN_VALUE)
        .slice(0, this.top);

      return orderBy(values, ['value'], ['desc']);
    },
    firstPage() {
      return this.filterCompetencies.slice(0, 3);
    },
    secondPage() {
      return this.filterCompetencies.slice(3);
    },
  },
};
</script>
