<template>
  <div
    class="report__chapter"
    :class="{ 'mt-5': print && !showTitle }"
    v-if="isShown"
  >
    <b-row>
      <b-col v-if="!print">
        <h3 class="report__chapter__title">
          <span v-if="!print" :class="icon"></span>
          <span>
            {{ report_title }}
            <!--
            <span v-if="score > -1" >
              <span :class="{ report__chapter__blur: isBlurred }" v-b-tooltip="{title: isBlurred ? $t('general.registration') : null, placement: 'top'}">
                {{ score }}/100
              </span>
            </span>
            -->
          </span>
        </h3>
      </b-col>
    </b-row>
    <h3 v-if="showTitle" class="report__chapter__title">
      <span>
        {{ report_title }}
      </span>
    </h3>
    <b-row>
      <b-col>
        <b-table
          class="report__ems__table"
          ref="table"
          :fields="fields"
          :items="items"
          :borderless="true"
          responsive="sm"
          fixed="fixed"
        >
          <template #head(value)>
            <div class="table__head">
              <span><!-- {{ $t("report.relative_weaker") }} --></span>
              <span v-if="!print">{{ $t('report.relative_stronger') }}</span>
            </div>
          </template>

          <template v-slot:cell()="{ item }">
            <span
              class="text-truncate"
              :class="{ report__chapter__blur: isBlurred }"
              v-b-tooltip="{
                title: isBlurred ? $t('general.registration') : null,
                placement: 'top',
              }"
            >
              {{ talentsAttr[attributeName][item.key][`name_${currentLang}`] }}
            </span>
          </template>

          <template v-slot:cell(value)="{ item }">
            <!-- :variant="item.value > selfTestScoreSuccess ? 'success' : 'primary'" -->
            <b-progress :max="max">
              <b-progress-bar
                :variant="variant"
                :value="item.value"
              ></b-progress-bar>
              <!-- :variant="index == 0 ? 'success' : 'primary'"  -->
            </b-progress>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SELF_TEST_SCORE_SUCESS } from '@/common/constants';
export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: [],
    },
    fields: {
      type: Array,
      required: true,
      default: [],
    },
    icon: {
      type: String,
      required: true,
      default: '',
    },
    report_title: {
      type: String,
      required: true,
      default: '',
    },
    variant: {
      type: String,
      required: false,
      default: 'primary',
    },
    talentsAttr: {
      type: Object,
      required: true,
      default: () => {},
    },
    currentLang: {
      type: String,
      required: true,
      default: 'en',
    },
    attributeName: {
      type: String,
      required: true,
      default: '',
    },
    max: {
      type: Number,
      required: true,
      default: 100,
    },
    score: {
      type: Number,
      required: true,
      default: 0,
    },
    isBlurred: {
      type: Boolean,
      required: false,
      default: false,
    },
    isShown: {
      type: Boolean,
      required: false,
      default: true,
    },
    print: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selfTestScoreSuccess: SELF_TEST_SCORE_SUCESS,
    };
  },
};
</script>
