import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import i18n from '../../../i18n';

const state = {
  currentLanguage: localStorage.getItem('language') || 'en',
  currentUserRole: localStorage.getItem('role') || '',
  currentUserId: localStorage.getItem('user-id') || '',
  isCompanyAdmin: false,
  userToken: localStorage.getItem('user-token') || '',
  currentUserFirstName: localStorage.getItem('first-name') || '',
  currentUserLastName: localStorage.getItem('last-name') || '',
  currentUserEmail: localStorage.getItem('email') || '',
  currentUserCompanyName: localStorage.getItem('company-name') || '',
  currentUserCountry: '',
  currentUserCompanies: [],
  selftestSurveyPassed: null,
  companyLogoUrl: '',
  attributes: {},
  permanentAttributes: [],
  redirectTo: localStorage.getItem('redirect-to') || null,
  showSpinner: false,
  spinnerOptions: {},
  // Config
  jobTypes: [],
  timeZones: [],
  titles: [],
  companyInfo: {},
  availableLanguages: [],
  availableCountries: [],
  availableCountriesWithKey: {},
  languageProficiencyLevels: [],
  educationGeneralAreas: [], // deprecated
  jobAreasOld: [], // deprecated
  yearsOfExperience: [],
  senorityLevel: [],
  educationAreas: [],
  educationGroups: [],
  educationSpecializations: [],
  jobAreas: [],
  jobGroups: [],
  jobSpecializations: [],
  bulkHiringTypes: [],
  bulkHiringProfiles: {},
  exitQuestions: {},
  // Calendar
  userCalendar: null,
  calendarLoading: false,
  calendarError: null
};

// Set user language
i18n.locale = state.currentLanguage;

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
