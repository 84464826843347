<template>
  <div class="availability-wrap" :style="{ 'padding-bottom': '6rem' }">
    <div class="d-flex align-items-center">
      <b-form-checkbox
        v-model="calendarEnabled"
        switch
        size="lg"
        class="calendar-toggle"
      >
      </b-form-checkbox>
      <label> {{ $t('calendar.enable_calendar') }}</label>
    </div>
    <div
      v-if="calendarEnabled"
      class="d-flex justify-content-between align-items-center"
    >
      <h2 class="form__title">{{ $t('calendar.availability') }}</h2>
    </div>

    <div v-if="calendarEnabled">
      <div class="date-range-settings mb-4">
        <b-row>
          <b-col lg="6">
            <div class="d-flex align-items-center mb-2">
              <b-form-checkbox
                v-model="isIndefiniteAvailability"
                switch
                size="lg"
                class="mr-3"
              >
              </b-form-checkbox>
              <label>{{ $t('calendar.indefinite_availability') }}</label>
            </div>

            <div
              v-if="!isIndefiniteAvailability"
              class="d-flex align-items-center"
            >
              <b-form-datepicker
                v-model="availabilityRange.start"
                :min="minDate"
                :max="availabilityRange.end || undefined"
                :locale="currentLanguage"
                placeholder="Start date"
                class="mr-2"
              ></b-form-datepicker>
              <span class="mx-2">-</span>
              <b-form-datepicker
                v-model="availabilityRange.end"
                :min="availabilityRange.start || minDate"
                :locale="currentLanguage"
                placeholder="End date"
              ></b-form-datepicker>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="timezone-settings mb-4">
        <b-row>
          <b-col lg="4" md="5">
            <legend class="col-form-label">
              {{ $t('general.timezone') }}*
            </legend>
            <multiselect
              v-model="selectedTimeZone"
              :options="timeZones"
              label="value"
              :class="{ 'multiselect-error': false }"
              :selectLabel="''"
              :deselectLabel="''"
              :selectedLabel="$t('general.selected')"
            ></multiselect>
          </b-col>
          <b-col lg="4">
            <legend class="col-form-label">
              {{ $t('calendar.time_format') }}
            </legend>
            <b-form-radio-group
              v-model="timeFormat"
              :options="[
                {
                  text: `12 ${$t(
                    'calendar.hour'
                  ).toLocaleLowerCase()} (1:00 PM)`,
                  value: '12',
                },
                {
                  text: `24 ${$t('calendar.hour').toLocaleLowerCase()} (13:00)`,
                  value: '24',
                },
              ]"
              stacked
            ></b-form-radio-group>
          </b-col>
        </b-row>
      </div>

      <!-- Add Minimum Booking Hours Dropdown -->
      <div class="minimum-booking-hours mb-4">
        <b-row>
          <b-col lg="4" md="5">
            <legend class="col-form-label">
              {{ $t('calendar.minimum_booking_notice') }}
            </legend>
            <b-form-select
              v-model="minimumBookingHours"
              :options="bookingHoursOptions"
              class="minimum-hours-select"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>

      <div class="weekly-schedule">
        <div v-for="(day, index) in weekDays" :key="index" class="day-row">
          <div class="day-toggle d-flex align-items-center mb-3">
            <b-form-checkbox
              v-model="day.enabled"
              switch
              size="lg"
              class="mr-2"
            ></b-form-checkbox>
            <span class="day-name">{{ $t(`calendar.weekday.${day.name.toLocaleLowerCase()}`) }}</span>
          </div>

          <div v-if="day.enabled" class="time-slots">
            <div
              v-for="(timeSlot, slotIndex) in day.timeSlots"
              :key="slotIndex"
              class="time-slot-row d-flex align-items-center mb-3"
            >
              <b-form-timepicker
                v-model="timeSlot.start"
                :locale="currentLanguage"
                :hour12="timeFormat === '12'"
                class="time-picker"
              ></b-form-timepicker>

              <span class="mx-2">-</span>

              <b-form-timepicker
                v-model="timeSlot.end"
                :locale="currentLanguage"
                :hour12="timeFormat === '12'"
                class="time-picker"
              ></b-form-timepicker>

              <div class="slot-actions ml-3">
                <b-button
                  v-if="slotIndex === day.timeSlots.length - 1"
                  variant="outline-primary"
                  size="sm"
                  @click="addTimeSlot(index)"
                  class="mr-2"
                >
                  <i class="mdi mdi-plus"></i>
                </b-button>

                <b-button
                  v-if="day.timeSlots.length > 1"
                  variant="outline-danger"
                  size="sm"
                  @click="removeTimeSlot(index, slotIndex)"
                >
                  <i class="mdi mdi-minus"></i>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-row>
        <b-col lg="4" md="5">
          <h2 class="form__title">{{ $t('calendar.contact') }}</h2>

          <div>
            <app-text-input
              :name="$t('create_campaign.phone_number')"
              :label="$t('create_campaign.phone_number')"
              vid="phone"
              type="text"
              placeholder="+123456789"
              v-model="phone"
            ></app-text-input>
          </div>

          <app-text-input
            :name="$t('general.address')"
            :label="$t('general.address')"
            vid="address"
            type="text"
            placeholder="Kaiserstraße 5, 60311 Frankfurt am Main, Deutschland"
            v-model="address"
          ></app-text-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-button
            class="mt-3 settings-save-btn"
            type="submit"
            variant="primary"
            @click="saveAvailability"
          >
            <b-spinner
              v-if="isLoading"
              variant="light"
              small="small"
              label="Spinning"
            ></b-spinner>
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppTextInput from '../../TextInput.vue';

export default {
  name: 'AvailabilityComponent',
  components: {
    AppTextInput,
  },
  data() {
    return {
      calendarEnabled: false,
      isIndefiniteAvailability: true,
      availabilityRange: {
        start: null,
        end: null,
      },
      weekDays: [
        {
          name: 'Sunday',
          enabled: false,
          timeSlots: [{ start: '09:00', end: '17:00' }],
        },
        {
          name: 'Monday',
          enabled: true,
          timeSlots: [{ start: '09:00', end: '17:00' }],
        },
        {
          name: 'Tuesday',
          enabled: true,
          timeSlots: [{ start: '09:00', end: '17:00' }],
        },
        {
          name: 'Wednesday',
          enabled: true,
          timeSlots: [{ start: '09:00', end: '17:00' }],
        },
        {
          name: 'Thursday',
          enabled: true,
          timeSlots: [{ start: '09:00', end: '17:00' }],
        },
        {
          name: 'Friday',
          enabled: true,
          timeSlots: [{ start: '09:00', end: '17:00' }],
        },
        {
          name: 'Saturday',
          enabled: false,
          timeSlots: [{ start: '09:00', end: '17:00' }],
        },
      ],
      selectedTimeZone: null,
      timeFormat: '24',
      address: '',
      phone: '',
      hasCalendarInDB: false,
      minimumBookingHours: 24,
      bookingHoursOptions: [
        { value: 1, text: `1 ${this.$t('calendar.hour').toLocaleLowerCase()}` },
        {
          value: 2,
          text: `2 ${this.$t('calendar.hours').toLocaleLowerCase()}`,
        },
        {
          value: 4,
          text: `4 ${this.$t('calendar.hours').toLocaleLowerCase()}`,
        },
        {
          value: 6,
          text: `6 ${this.$t('calendar.hours').toLocaleLowerCase()}`,
        },
        {
          value: 12,
          text: `12 ${this.$t('calendar.hours').toLocaleLowerCase()}`,
        },
        {
          value: 24,
          text: `24 ${this.$t(
            'calendar.hours'
          ).toLocaleLowerCase()} (1 ${this.$t(
            'calendar.day'
          ).toLocaleLowerCase()})`,
        },
        {
          value: 48,
          text: `48 ${this.$t(
            'calendar.hour'
          ).toLocaleLowerCase()} (2 ${this.$t(
            'calendar.days'
          ).toLocaleLowerCase()})`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentLanguage: 'global/currentLanguage',
      timeZones: 'global/timeZones',
      userCalendar: 'global/userCalendar',
      calendarLoading: 'global/calendarLoading',
      calendarError: 'global/calendarError',
      hasCalendar: 'global/hasCalendar',
    }),
    minDate() {
      return new Date().toISOString().split('T')[0]; // Today in YYYY-MM-DD format
    },
    isLoading() {
      return this.calendarLoading;
    },
  },
  watch: {
    // Watch for calendar enabled changes to create or update calendar
    calendarEnabled(newValue) {
      if (newValue && !this.hasCalendarInDB) {
        // User enabled calendar and it doesn't exist in DB yet
        this.createCalendar();
      } else if (this.hasCalendarInDB) {
        // Calendar exists, just update the enabled status
        this.updateCalendarEnabledStatus();
      }
    },
  },
  mounted() {
    this.fetchCalendar();

    this.$store.dispatch('global/fetchTimeZones').then(() =>
      this.$nextTick(() => {
        this.selectDefaultTimezone();
      })
    );
  },
  methods: {
    calendarData() {
      // If indefinite, we set both dates to null
      const dateRange = this.isIndefiniteAvailability
        ? { start: null, end: null }
        : this.availabilityRange;

      return {
        enabled: this.calendarEnabled,
        indefinite_availability: this.isIndefiniteAvailability,
        availability_start_date: dateRange.start,
        availability_end_date: dateRange.end,
        time_zone: this.selectedTimeZone?.value,
        time_format: this.timeFormat,
        weekly_schedule: this.formatWeeklySchedule(),
        phone_number: this.phone,
        address: this.address,
        minimum_booking_hours: this.minimumBookingHours,
      };
    },
    formatWeeklySchedule() {
      // Convert our array to the format expected by the backend
      const schedule = {};
      this.weekDays.forEach((day) => {
        schedule[day.name] = {
          enabled: day.enabled,
          timeSlots: day.enabled ? day.timeSlots : [],
        };
      });
      return schedule;
    },
    selectDefaultTimezone() {
      // Try to get user's timezone from store or browser
      const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timeZone = this.timeZones.find(
        (tz) => tz.value === currentTimeZone
      );
      if (timeZone) {
        this.selectedTimeZone = timeZone;
      } else if (this.timeZones.length > 0) {
        this.selectedTimeZone = this.timeZones[0];
      }
    },
    fetchCalendar() {
      this.$store
        .dispatch('global/fetchUserCalendar')
        .then((calendar) => {
          if (calendar) {
            this.hasCalendarInDB = true;
            this.updateComponentFromCalendarData(calendar);
          } else {
            // No calendar found
            this.hasCalendarInDB = false;
            this.calendarEnabled = false;
          }
        })
        .catch(() => {
          this.hasCalendarInDB = false;
          this.calendarEnabled = false;
        });
    },
    updateComponentFromCalendarData(calendar) {
      // Update calendar enabled state
      this.calendarEnabled = calendar.enabled;

      // Handle indefinite availability setting
      this.isIndefiniteAvailability = calendar.indefinite_availability;

      // Update date range if available and not indefinite
      if (!this.isIndefiniteAvailability) {
        this.availabilityRange = {
          start: calendar.availability_start_date || null,
          end: calendar.availability_end_date || null,
        };
      }

      // Update time format
      this.timeFormat = calendar.time_format || '24';

      // Update contact info
      this.phone = calendar.contact_info.phone_number || '';
      this.address = calendar.contact_info.address || '';

      // Update minimum booking hours if available
      if (calendar.minimum_booking_hours !== undefined) {
        this.minimumBookingHours = calendar.minimum_booking_hours;
      }

      // Update weekly schedule
      if (calendar.weekly_schedule) {
        Object.keys(calendar.weekly_schedule).forEach((dayName) => {
          const dayData = calendar.weekly_schedule[dayName];
          const dayIndex = this.weekDays.findIndex((d) => d.name === dayName);

          if (dayIndex !== -1) {
            this.weekDays[dayIndex].enabled = dayData.enabled;
            if (dayData.timeSlots && dayData.timeSlots.length > 0) {
              this.weekDays[dayIndex].timeSlots = dayData.timeSlots;
            }
          }
        });
      }

      // Set timezone if available
      if (calendar.time_zone) {
        const timeZone = this.timeZones.find(
          (tz) => tz.value === calendar.time_zone
        );
        if (timeZone) {
          this.selectedTimeZone = timeZone;
        }
      }
    },
    createCalendar() {
      this.$store
        .dispatch('global/createUserCalendar', this.calendarData())
        .then((calendar) => {
          this.hasCalendarInDB = true;
          this.$bvToast.toast(this.$t('calendar.notifications.calendar_created'), {
            variant: 'success',
            toaster: 'b-toaster-top-right',
            autoHideDelay: 3000,
          });
        })
        .catch((error) => {
          this.calendarEnabled = false;
          this.$bvToast.toast(
            error.message || this.$t('calendar.errors.error_creating_calendar'),
            {
              variant: 'danger',
              toaster: 'b-toaster-top-right',
              autoHideDelay: 5000,
            }
          );
        });
    },
    updateCalendarEnabledStatus() {
      // Just update the enabled status
      this.$store
        .dispatch('global/updateUserCalendar', {
          enabled: this.calendarEnabled,
        })
        .catch((error) => {
          // Revert UI state on error
          this.calendarEnabled = !this.calendarEnabled;
          this.$bvToast.toast(
            error.message || this.$t('calendar.errors.error_updating_calendar'),
            {
              variant: 'danger',
              toaster: 'b-toaster-top-right',
              autoHideDelay: 5000,
            }
          );
        });
    },
    addTimeSlot(dayIndex) {
      const lastSlot =
        this.weekDays[dayIndex].timeSlots[
          this.weekDays[dayIndex].timeSlots.length - 1
        ];
      const newEndTime = this.incrementTime(lastSlot.end, 1);
      this.weekDays[dayIndex].timeSlots.push({
        start: lastSlot.end,
        end: newEndTime,
      });
    },
    removeTimeSlot(dayIndex, slotIndex) {
      this.weekDays[dayIndex].timeSlots.splice(slotIndex, 1);
    },
    incrementTime(time, hours) {
      const [h, m] = time.split(':').map(Number);
      let newHour = h + hours;
      if (newHour >= 24) newHour = 23;
      return `${String(newHour).padStart(2, '0')}:${String(m).padStart(
        2,
        '0'
      )}`;
    },
    copyTimes(sourceDayIndex) {
      const sourceDay = this.weekDays[sourceDayIndex];
      if (!sourceDay.enabled || sourceDay.timeSlots.length === 0) return;

      // Show confirmation dialog
      this.$bvModal
        .msgBoxConfirm(this.$t('general.copy_times_confirmation'), {
          title: this.$t('general.confirm'),
          okVariant: 'primary',
          okTitle: this.$t('general.yes'),
          cancelTitle: this.$t('general.no'),
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.weekDays.forEach((day, index) => {
              if (index !== sourceDayIndex && day.enabled) {
                day.timeSlots = JSON.parse(JSON.stringify(sourceDay.timeSlots));
              }
            });
          }
        });
    },
    saveAvailability() {
      if (!this.hasCalendarInDB) {
        this.createCalendar();
        return;
      }

      this.$store
        .dispatch('global/updateUserCalendar', this.calendarData())
        .then(() => {
          this.$bvToast.toast(this.$t('calendar.notifications.availability_saved'), {
            variant: 'success',
            toaster: 'b-toaster-top-right',
            autoHideDelay: 3000,
          });
        })
        .catch((error) => {
          this.$bvToast.toast(
            error.message || this.$t('calendar.errors.error_saving'),
            {
              variant: 'danger',
              toaster: 'b-toaster-top-right',
              autoHideDelay: 5000,
            }
          );
        });
    },
  },
};
</script>

<style scoped>
.availability-wrap {
  margin-bottom: 2rem;
}

.calendar-toggle {
  font-size: 1rem;
}

.day-row {
  margin-bottom: 1.5rem;
}

.day-name {
  font-weight: 500;
  min-width: 100px;
}

.time-picker {
  width: 150px;
}

.time-slot-row {
  padding-left: 2rem;
}

.slot-actions {
  display: flex;
}

.minimum-hours-select {
  width: 100%;
}

@media (max-width: 768px) {
  .time-slot-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .time-picker {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .slot-actions {
    margin-top: 0.5rem;
    margin-left: 0 !important;
  }
}
</style>