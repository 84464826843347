<template>
  <div class="row text-center pt-5">
    <div class="col-3 position-relative">
      <div
        class="report__lineleft"
        :style="{ backgroundColor: getColor() }"
      ></div>
    </div>
    <div class="col-6">
      <h2 class="report__personality__title w-100 text-capitalize">
        {{ title }}
      </h2>
    </div>
    <div class="col-3 position-relative">
      <div
        class="report__lineright"
        :style="{ backgroundColor: getColor() }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      colors: {
        violet: '#ae4a84',
        blue3: '#56698f',
        blue2: '#6188cd',
        brown: '#ae928c',
        green2: '#8eae8c',
      },
    };
  },
  methods: {
    getColor() {
      return this.colors[this.variant] || this.colors['green2'];
    },
  },
};
</script>