const SET_COMPANY_CALENDARS = (state, calendars) => {
  state.companyCalendars = calendars;
};

const SET_EVENT_DATA = (state, eventData) => {
  state.eventData = eventData;
};

const SET_AVAILABLE_SLOTS = (state, slots) => {
  state.availableSlots = slots;
}

const SET_BOOKED_EVENT = (state, event) => {
  state.bookedEvent = event;
}

const SET_CAMPAIGN_EVENTS = (state, events) => {
  state.campaignEvents = events;
}


export default {
  SET_COMPANY_CALENDARS,
  SET_EVENT_DATA,
  SET_AVAILABLE_SLOTS,
  SET_BOOKED_EVENT,
  SET_CAMPAIGN_EVENTS,
};
