<template>
  <article class="campaign">
    <b-breadcrumb
      v-if="!$route.meta.sharedCampaign"
      :items="breadcrumbs"
    ></b-breadcrumb>

    <!-- campaign header ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <header class="vue-form-wizard campaign__header">
      <h1 class="campaign__title">{{ info.job_title_short }}</h1>
    </header>

    <!-- action buttons ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <div v-if="!$route.meta.sharedCampaign" class="dashboard-table-wrap">
      <div class="dashboard-table-top d-flex flex-wrap">
        <div class="d-flex flex-wrap">
          <b-button
            @click="edit"
            v-b-tooltip.hover
            :title="$t('dashboard.edit_campaign')"
            class="mr-3 btn-action"
          >
            <span class="mdi mdi-pencil"></span>
          </b-button>

          <b-button
            @click="updateState('cancel', 'canceled')"
            :disabled="
              current.state === 'canceled' ||
              current.state === 'closed' ||
              current.state === 'archived' ||
              current.state === 'finalized'
            "
            v-b-tooltip.hover
            :title="$t('dashboard.cancel_campaign')"
            class="mr-3 btn-action"
          >
            <span class="mdi mdi-close"></span>
          </b-button>

          <b-button
            @click="updateState('archive', 'archived')"
            :disabled="
              current.state === 'canceled' || current.state === 'archived'
            "
            v-b-tooltip.hover
            :title="$t('dashboard.archive_campaign')"
            class="mr-3 btn-action"
          >
            <span class="mdi mdi-archive"></span>
          </b-button>

          <b-button
            @click="updateState('close', 'closed')"
            :disabled="
              current.state === 'canceled' ||
              current.state === 'closed' ||
              current.state === 'archived' ||
              current.state === 'finalized'
            "
            v-b-tooltip.hover
            :title="$t('dashboard.close_campaign')"
            class="mr-3 btn-action"
          >
            <span class="mdi mdi-timer-off"></span>
          </b-button>
        </div>
      </div>
    </div>

    <!-- campaign content ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~-->
    <div class="d-flex justify-content-end">
      <b-form-checkbox class="pt-2 mr-3 d-none d-lg-block" v-model="showAside"
        >{{ $t('dashboard.details') }}
      </b-form-checkbox>
    </div>

    <b-row>
      <b-col :class="showAside ? 'col-lg-9' : 'col-lg-12'">
        <b-tabs>
          <b-tab :title="tabTitle" ref="candidatesTabRef">
            <b-row>
              <b-col>
                <div v-if="showNoCandidates" class="emptystate">
                  <i class="mdi mdi-information-outline"></i>
                  <p>{{ $t('applicant_portal.no_candidates_available') }}</p>
                </div>
                <APCampaignTable
                  v-if="!showNoCandidates && current.is_applicant_portal"
                  :items="campaignCandidates"
                  :paginationDict="campaignCandidatesPagination"
                  :companyId="currentUserCompanyId"
                  :campaignId="current.id"
                  :campaignName="current.info.job_title_short"
                  :campaignCandidatesAPData="campaignCandidatesAPData"
                  :showSpinner="showTableSpinner"
                  :referencesRequested="referencesRequestedPerCandidate"
                  :availableLanguages="availableLanguages"
                  :isBulkHiring="current.ap_data.bulk_hiring"
                  :sort="sort"
                  :isPreview="!!$route.meta.sharedCampaign"
                  @pageChange="onPageChange"
                  @sortingChanged="onSortChange"
                  @anonymize="fetchCandidates"
                  @fetchCandidates="
                    $route.meta.sharedCampaign
                      ? fetchPreviewCandidates
                      : fetchCandidates
                  "
                />

                <ManualCampaignTable
                  v-if="!showNoCandidates && !current.is_applicant_portal"
                  :items="contacts"
                />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            ref="reportTabRef"
            id="container"
            :title="$t('dashboard.report')"
            @click="getReportData(anonymous)"
          >
            <b-row>
              <b-col v-if="current.report_present">
                <Spinner
                  v-if="inProgress"
                  :options="spinnerOptions"
                  class="tab__spinner"
                />
                <web-report
                  v-if="!inProgress"
                  :id="'webreport'"
                  @getReport="getReportDataNoLoading"
                ></web-report>
              </b-col>

              <b-col v-else>
                <div class="emptystate">
                  <i class="mdi mdi-information-outline"></i>
                  <!-- TODO: Translate this -->
                  <p>{{ $t('applicant_portal.no_report_available') }}</p>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <!-- TODO: Translate title -->
          <b-tab
            id="container"
            v-if="current.slug && !$route.meta.sharedCampaign"
            :title="$t('applicant_portal.link')"
          >
            <b-row>
              <b-col>
                <div class="form__title">
                  {{ $t('applicant_portal.link_to_applicant') }}
                </div>
                <CopyLink :keyVal="this.current.id" :slug="campaignSlug" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="form__title">
                  {{ $t('create_campaign.magic_link') }}
                </div>
                <CopyLink
                  :keyVal="this.current.id"
                  :slug="campaignSlug"
                  :mode="'e'"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><div class="form__title">
                  {{ $t('applicant_portal.share_link') }}
                </div></b-col
              >
            </b-row>
            <b-row>
              <b-col v-if="shareLink" sm="4" md="4">
                <CopyLink
                  style="margin-top: 23px !important"
                  :staticLink="shareLink"
                  :mode="'e'"
              /></b-col>
              <b-col sm="2" md="2">
                <b-form-group :label="$t('applicant_portal.link_exp_period')">
                  <multiselect
                    v-model="linkExpirationPeriod"
                    :searchable="false"
                    label="text"
                    selectLabel=""
                    :options="[
                      { value: 1, text: `1 ${this.$t('general.day')}` },
                      { value: 7, text: `7 ${this.$t('general.days')}` },
                      { value: 15, text: `15 ${this.$t('general.days')}` },
                      { value: 30, text: `30 ${this.$t('general.days')}` },
                    ]"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="2" md="2">
                <b-button
                  style="margin-top: 24px"
                  @click="generatePreviewToken"
                  >{{ $t('general.generate') }}</b-button
                >
              </b-col>
            </b-row>
          </b-tab>
          <b-tab v-if="id" title="Calendar">
            <ScheduledEvents :campaignId="id" />
          </b-tab>
        </b-tabs>
      </b-col>

      <!--  // aside ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <b-col lg="3" class="aside" :class="{ 'd-lg-none': !showAside }">
        <div class="aside__content campaign__detail__screen">
          <!--  // sticky report settings ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <!-- <div class="sticky-aside-content">
            <h6 class="aside__title">
              <span>Sticky</span>
            </h6>
          </div> -->

          <!--  // campaign details ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <h6 class="aside__title">
            <span>{{ $t('create_campaign.campaign_details') }}</span>
          </h6>

          <div class="campaign__attributes">
            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('dashboard.status') }}
              </div>
              <div class="campaign__attribute__value">
                <div :class="'table-label table-label__' + currentState">
                  {{ $t(`dashboard.statuses.${currentState}`) }}
                </div>
              </div>
            </div>

            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('report.campaign_id') }}
              </div>
              <div class="campaign__attribute__value">{{ campaignId }}</div>
            </div>

            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('create_campaign.internal_job_id') }}
              </div>
              <div class="campaign__attribute__value">
                {{ info.internal_job_id_1 }}
              </div>
            </div>

            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('dashboard.client_name') }}
              </div>
              <div class="campaign__attribute__value">
                {{ info.client_name }}
              </div>
            </div>

            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('create_campaign.campaign_type') }}
              </div>
              <div class="campaign__attribute__value">{{ campaignType }}</div>
            </div>

            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('create_campaign.min_references_requested') }}
              </div>
              <div class="campaign__attribute__value">
                {{ info.references_requested }}
              </div>
            </div>

            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('dashboard.received_responses') }}
              </div>
              <div class="progress__bar">
                <div class="progress__value">
                  {{
                    calculateCampaignProgress(
                      referencesPassed,
                      referencesRequested
                    ) + '%'
                  }}
                </div>
                <div
                  class="progress__line"
                  v-bind:style="{
                    width:
                      calculateCampaignProgress(
                        referencesPassed,
                        referencesRequested
                      ) + '%',
                  }"
                  v-bind:class="{
                    'progress__line--finished':
                      calculateCampaignProgress(
                        referencesPassed,
                        referencesRequested
                      ) === 100,
                  }"
                ></div>
                <div class="progress__background"></div>
              </div>
            </div>

            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('create_campaign.starting_date') }}
              </div>
              <div class="campaign__attribute__value">
                {{ startDate }}
              </div>
            </div>

            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('create_campaign.end_date') }}
              </div>
              <div class="campaign__attribute__value">
                {{ endDate }}
              </div>
            </div>

            <div class="campaign__attribute__item">
              <div class="campaign__attribute__label">
                {{ $t('dashboard.recruiter_name') }}
              </div>
              <div class="campaign__attribute__value">{{ recruiterName }}</div>
            </div>
          </div>

          <!--  // skill profile ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

          <h6 class="aside__title">
            <span>{{ $t('create_campaign.skills_competencies') }}</span>
          </h6>

          <div class="skill__profile">
            <div v-if="showNoCompetencies" class="emptystate">
              <i class="mdi mdi-information-outline"></i>
              <p>{{ $t('applicant_portal.no_competencies_available') }}</p>
            </div>

            <div v-if="!showNoCompetencies" class="skills-item-wrp">
              <div
                class="skill__item"
                v-for="(item, index) in traits"
                :key="'traits' + index"
              >
                <div class="skill__item__label">
                  {{ item[`name_${currentLanguage}`] }}
                </div>
                <div class="skill__item__value">{{ item.value }}</div>
                <div class="skill__item__progress">
                  <div
                    class="skill__item__progressbar"
                    :style="{ width: item.value + '%' }"
                  ></div>
                </div>
              </div>

              <!-- <div
                class="skill__item"
                v-for="(item, index) in personalityAttrs.values"
                :key="'values' + index"
              >
                <div class="skill__item__label">
                  {{ item[`name_${currentLanguage}`] }}
                </div>
                <div class="skill__item__value">{{ item.value }}</div>
                <div class="skill__item__progress">
                  <div
                    class="skill__item__progressbar"
                    :style="{ width: item.value + '%' }"
                  ></div>
                </div>
              </div> -->

              <div
                class="skill__item"
                v-for="(item, index) in competencies"
                :key="'competencies' + index"
              >
                <div class="skill__item__label">
                  {{ item[`name_${currentLanguage}`] }}
                </div>
                <div class="skill__item__value">{{ item.value }}</div>
                <div class="skill__item__progress">
                  <div
                    class="skill__item__progressbar"
                    :style="{ width: item.value + '%' }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import AppTable from './Table';
import APCampaignTable from './APCampaignTable';
import ManualCampaignTable from './ManualCampaignTable';
import Spinner from '@/components/Spinner';
import CopyLink from '@/components/CopyLink';
import copyToClipboard from '@/common/copyToClipboard';
import WebReport from '@/views/Report/WebReport';
import { calculateCampaignProgress } from '@/common/utils';
import { CAMPAIGN_TYPES } from '../../../common/constants';
import ApCampaignService from '@/services/ap-campaign.service';
import env from '../../../../config/enviroment';
import ScheduledEvents from '../../../components/Calendar/ScheduledEvents/Index.vue';

export default {
  components: {
    Spinner,
    WebReport,
    AppTable,
    APCampaignTable,
    ManualCampaignTable,
    CopyLink,
    ScheduledEvents,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    campaignLink: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: { name: 'Home' },
        },
        {
          text: 'Campaign Detail',
          active: true,
        },
        {
          text: '',
          active: true,
        },
      ],
      inProgress: true,
      contacts: [],
      showTableSpinner: true,
      apCampaignService: null,
      page: 1,
      sort: {
        sortBy: 'match_score',
        sortDesc: true,
      },
      shareLink: '',
      linkExpirationPeriod: { value: 30, text: '30 days' },
    };
  },
  computed: {
    ...mapGetters('campaign', [
      'current',
      'campaignCandidates',
      'campaignCandidatesPagination',
      'campaignCandidatesAPData',
      'anonymous',
      'showDetails',
    ]),
    ...mapGetters('global', [
      'availableLanguages',
      'currentLanguage',
      'spinnerOptions',
      'titles',
      'currentUserCompanyId',
    ]),
    ...mapGetters('report', ['report', 'reportCampaign']),
    isProduction() {
      return env.NAME === 'production';
    },
    showAside: {
      get() {
        return this.showDetails;
      },
      set(value) {
        this.$store.commit('campaign/SET_SHOW_DETAILS', value);
      },
    },
    passive_candidates: {
      get() {
        return this.current.info.passive_candidates || false;
      },
      set(value) {
        this.$store.dispatch('campaign/updateStateCampaign', {
          ...this.current,
          info: {
            ...this.current.info,
            passive_candidates: value,
          },
        });
      },
    },
    campaignSlug() {
      return this.current.slug;
    },
    personalityAttrs() {
      return this.current.personality_attrs || {};
    },
    /*
     * HACK: Tmp solution to prevent empty labels
     * for depricated/removed/hidden attributes
     */
    competencies() {
      return this.personalityAttrs.competencies.filter(
        (obj) => `name_${this.currentLanguage}` in obj
      );
    },
    traits() {
      return this.personalityAttrs.traits.filter(
        (obj) => `name_${this.currentLanguage}` in obj
      );
    },
    /*
     * End of HACK
     */
    info() {
      return this.current.info || {};
    },
    referencesRequested() {
      return this.current.references_requested || 3;
    },
    referencesPassed() {
      return this.current.references_passed;
    },
    referencesRequestedPerCandidate() {
      return this.current.references_requested_per_candidate;
    },
    campaignId() {
      if (!this.id) {
        return null;
      }
      return this.id.split('-')[0].toUpperCase();
    },
    currentState() {
      return this.current && this.current.state
        ? this.current.state.toLowerCase()
        : '';
    },

    startDate() {
      return this.current.start_date || '';
    },
    endDate() {
      return this.current.end_date || '';
    },
    recruiterName() {
      return this.current.recruiter_name || '';
    },
    showNoCompetencies() {
      return !this.personalityAttrs.competencies;
    },
    showNoCandidates() {
      return !this.current.contacts.length;
    },
    reportUrl() {
      if (!this.id) {
        return null;
      }
      return '/campaigns/' + this.id + '/report/view';
    },
    tabTitle() {
      if (this.current.is_applicant_portal) {
        return this.$t('create_campaign.candidates');
      }

      let titleKey = '';
      switch (this.current.campaign_type) {
        case CAMPAIGN_TYPES.TEAM:
          titleKey = 'create_campaign.team_members';
          break;
        case CAMPAIGN_TYPES.SELFTEST:
          titleKey = 'create_campaign.attendees';
          break;
        default:
          titleKey = 'create_campaign.candidates';
          break;
      }
      return this.$t(titleKey);
    },
    campaignType() {
      return this.$t(`create_campaign.${this.current.campaign_type}_title`);
    },
  },
  watch: {
    info() {
      this.breadcrumbs[2].text = `${this.info.job_title_short}`;
    },
    '$route.query'() {
      this.fetchCandidates();
    },
  },
  created() {
    this.$store.dispatch('global/fetchTitles').then(() => {
      if (this.$route.meta.sharedCampaign) {
        this.$store
          .dispatch('campaign/getCampaignPreview', this.$route.query._token)
          .then(() => {
            this.fetchPreviewCandidates();
          })
          .catch((err) => console.log('error', err));
      } else {
        this.getCampaignData().then(() => {
          this.setContacts();
          this.navigateToReport();

          // TODO: Fetch candidates for AP campaign
          this.fetchCandidates();
        });
      }
    });
    this.$store.dispatch('global/fetchApplicantPortalConfig');
  },
  mounted() {},
  methods: {
    setContacts() {
      if (this.current.contacts.length) {
        this.current.contacts.forEach((item) => {
          item.references_requested_formated = `${
            item.references_passed
          } ${this.$t('general.of')} ${
            this.current.references_requested_per_candidate
          }`;
          const title = this.titles.find((t) => item.gender === t.value);
          item.title = title ? title.text : '';
        });
        this.contacts = [].concat(this.current.contacts);
      }
    },
    navigateToReport() {
      if (this.$route.hash === '#report') {
        this.$refs.reportTabRef.activate();
        this.getReportData(this.anonymous);
        this.scrollToReport();
      }
    },
    toClipboard() {
      copyToClipboard(this.$refs.application_link);
    },
    edit() {
      if (this.current.is_applicant_portal) {
        this.$router.push(`/campaigns/ap/${this.current.id}/edit`);
      } else {
        this.$router.push(`/campaigns/${this.current.id}/edit`);
      }
    },
    updateState(state, newState) {
      this.$store.dispatch('campaign/updateState', {
        campaign_ids: [this.current.id],
        state: state,
        newState: newState,
      });
    },
    getReportData(mode = false) {
      this.inProgress = true;

      const query = {
        id: this.$route.params.id,
        mode: mode,
        isApplicantPortal: this.current.is_applicant_portal,
        token: this.$route.query._token,
      };

      if (this.current.is_applicant_portal) {
        if (!this.apCampaignService) {
          this.apCampaignService = new ApCampaignService(this.current.id);
        }
        query.candidates = this.apCampaignService.getCandidatesIds();
        if (!query.candidates || !query.candidates.length) {
          this.$refs.candidatesTabRef.activate();
        }
      }

      this.$store
        .dispatch(
          this.$route.meta.sharedCampaign
            ? 'report/getReportPreview'
            : 'report/getReport',
          query
        )
        .then(() => (this.inProgress = false));
    },
    getReportDataNoLoading(mode) {
      const query = {
        id: this.$route.params.id,
        mode: mode,
        isApplicantPortal: this.current.is_applicant_portal,
      };

      if (this.current.is_applicant_portal) {
        if (!this.apCampaignService) {
          this.apCampaignService = new ApCampaignService(this.current.id);
        }
        query.candidates = this.apCampaignService.getCandidatesIds();
        if (!query.candidates || !query.candidates.length) {
          this.$refs.candidatesTabRef.activate();
        }
      }

      this.$store.dispatch('report/getReport', query);
    },
    getCampaignData(mode = false) {
      return this.$store.dispatch('campaign/getCampaign', this.id);
    },
    generatePreviewToken() {
      this.$store
        .dispatch('campaign/generatePreviewToken', {
          id: this.id,
          linkExpirationPeriod: this.linkExpirationPeriod.value,
        })
        .then((res) => {
          this.shareLink = `${location.origin}/preview/campaign?_token=${res.data.token}&lang=${this.currentLanguage}`;
        });
    },
    calculateCampaignProgress: calculateCampaignProgress,
    scrollToReport() {
      setTimeout(() => {
        this.$el.parentElement.parentElement.scrollTop =
          this.$refs.reportTabRef.$el.offsetTop + 120;
      }, 500);
    },
    onSortChange(sort) {
      if (this.sort.sortBy !== sort.sortBy) {
        sort.sortDesc = true;
      }
      this.sort = sort;
      if (this.$route.meta.sharedCampaign) {
        this.fetchPreviewCandidates();
      } else {
        this.fetchCandidates();
      }
    },
    onPageChange(page) {
      this.page = page;
      if (this.$route.meta.sharedCampaign) {
        this.fetchPreviewCandidates();
      } else {
        this.fetchCandidates();
      }
    },
    fetchCandidates() {
      this.showTableSpinner = true;
      this.$store
        .dispatch('campaign/fetchCandidates', {
          companyId: this.currentUserCompanyId,
          campaignId: this.current.id,
          page: this.$route.query.page || 1,
          sortBy: this.$route.query.sortBy || 'match_score',
          sortDesc: this.$route.query.sortDesc || true,
          filter: this.$route.query.filter || '',
          field: this.$route.query.field || '',
          education_keywords: this.$route.query.education_keywords || [],
          job_keywords: this.$route.query.job_keywords || [],
          certificates: this.$route.query.certificates || [],
          top: this.$route.query.top || '',
        })
        .then(() => (this.showTableSpinner = false))
        .catch(() => (this.showTableSpinner = false));
    },
    fetchPreviewCandidates() {
      this.showTableSpinner = true;
      this.$store
        .dispatch('campaign/fetchPreviewCandidates', {
          token: this.$route.query._token,
          page: this.page,
          sortBy: this.sort.sortBy,
          sortDesc: this.sort.sortDesc,
          education_keywords: this.$route.query.education_keywords || [],
          job_keywords: this.$route.query.job_keywords || [],
          certificates: this.$route.query.certificates || [],
        })
        .then(() => (this.showTableSpinner = false))
        .catch(() => (this.showTableSpinner = false));
    },
  },
};
</script>
<style scoped>
.aside {
  animation: slideIn 0.2s cubic-bezier(0.2, 0, 0, 1);
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>