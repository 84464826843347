<template>
  <section
    class="page-break-before-always page-break-inside-avoid page_campaign_summary"
  >
    <header-title v-if="isTitle" :title="title" :variant="variant"></header-title>
    <div v-for="item in radialProgress" :key="item.key" class="mt-10">
      <div class="personality__progress__item ml-5 mb-3">
        <report-radial-progress
          :value="item.score"
          :color="getColor()"
          :width="12"
        ></report-radial-progress>
        <span class="personality__progress__label">
          {{
            talentsAttr[attributeName][item.attribute][`name_${currentLang}`]
          }}
        </span>
      </div>
      <p class="personality__progress__description">
        {{ attrDescription[item.attribute] }}
      </p>
    </div>
  </section>
</template>
<script>
import { RADIAL_PROGRESS_MULTIPLY } from '@/common/constants';
import RadialProgress from '@/components/RadialProgress';
import HeaderTitle from './HeaderTitle';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    personalityType: {
      type: Array,
      required: true,
      default: [],
    },
    talentsAttr: {
      type: Object,
      default: () => {},
    },
    attrDescription: {
      type: Object,
      required: true,
      default: () => {},
    },
    currentLang: {
      type: String,
      default: 'en',
    },
    attributeName: {
      type: String,
      required: true,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    isTitle: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    HeaderTitle,
    ReportRadialProgress: RadialProgress,
  },
  data() {
    return {
      personalityColors: {
        violet: '#ae4a84',
        blue3: '#56698f',
        blue2: '#6188cd',
        brown: '#ae928c',
        green2: '#8eae8c',
      },
    };
  },
  computed: {
    radialProgress() {
      return (
        (this.personalityType &&
          this.personalityType.map((item, index) => {
            return {
              color_key: item.key,
              score: item.value,
              attribute: item.key,
            };
          })) ||
        []
      );
    },
  },
  methods: {
    multiplyRadialScore(index, value) {
      return index === 1 ? Math.round(value * RADIAL_PROGRESS_MULTIPLY) : value;
    },
    getColor() {
      return this.personalityColors[this.variant] || 'transparent';
    },
  },
};
</script>
<style lang="scss" scoped>
.circle__progress {
  width: 100px;
}
.report .personality__progress__label {
  left: 120px;
  font-size: 2.4rem;
}
</style>