<template>
  <section
    class="page-break-before-always page-break-inside-avoid page_campaign_summary"
  >
    <header-title
      :title="$t('report.your_personality_type')"
      :variant="variant"
    ></header-title>

    <div class="report__about">
      <h6>{{ $t('report.what_would_say') }}</h6>
      <p style="white-space: pre-wrap">
        {{ $t('report.insights_discovery') }}
      </p>
    </div>
    <div
      v-for="(item, index) in radialProgress.slice(0, 2)"
      :key="index"
      class="row mt-5"
    >
      <div class="col-3 ml-5">
        <div class="row">
          <div class="col-12 mb-2">
            <span class="personality__label"
              >{{ getMessageByColor(item.attribute) }}!</span
            >
          </div>
          <div class="col-12">
            <report-radial-progress
              :value="item.score"
              :color="getColor(item.attribute)"
              :width="16"
            ></report-radial-progress>
          </div>
        </div>
      </div>
      <div class="col-8 ml-4">
        <h6 class="">{{ getMessageByColor(item.attribute) }}!</h6>
        <p class="personality__description">
          {{
            $t(
              `js_report.personality_types.${personalityColors[item.attribute]}`
            )
          }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import {
  RADIAL_PROGRESS_MULTIPLY,
  PERSONALITY_TYPES_COLORS,
  PERSONALITY_TYPES_COLORS_TITLES,
} from '@/common/constants';
import RadialProgress from '@/components/RadialProgress';
import HeaderTitle from './HeaderTitle';
import PrintHeader from '../../Report/Print/PrintHeader';

export default {
  components: {
    ReportRadialProgress: RadialProgress,
    HeaderTitle,
    PrintHeader,
  },
  props: {
    personalityType: {
      type: Array,
      required: true,
      default: [],
    },
    fullName: {
      type: String,
      default: '',
    },
    talentsAttr: {
      type: Object,
      required: true,
      default: () => {},
    },
    currentLang: {
      type: String,
      required: true,
      default: 'en',
    },
    isRegistered: {
      type: Boolean,
      required: true,
      default: false,
    },
    variant: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      personalityColors: {
        score_g_1_1: 'red',
        score_g_1_2: 'green',
        score_g_1_3: 'blue',
        score_g_1_4: 'yellow',
      },
    };
  },
  computed: {
    radialProgress() {
      return (
        (this.personalityType &&
          this.personalityType.map((item, index) => {
            return {
              color_key: item.key,
              score: this.multiplyRadialScore(index, item.value),
              attribute: item.key,
            };
          })) ||
        []
      );
    },
  },
  methods: {
    multiplyRadialScore(index, value) {
      return index === 1 ? Math.round(value * RADIAL_PROGRESS_MULTIPLY) : value;
    },
    getColor(attribute) {
      return PERSONALITY_TYPES_COLORS[attribute] || 'transparent';
    },
    getMessageByColor(attribute) {
      return this.$t(
        PERSONALITY_TYPES_COLORS_TITLES[attribute] ||
          this.talentsAttr['values'][attribute][`name_${this.currentLang}`] ||
          ''
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.circle__progress {
  width: 160px;
}
.report .personality__progress__label {
  left: 100px;
  font-size: 2.4rem;
}
.personality__description {
  white-space: pre-wrap;
  line-height: 30px;
  font-size: 16px;
}
</style>