<template>
  <div class="page_interview_questions">
    <section
      class="page-break-before-always page-break-inside-avoid page_campaign_summary"
    >
      <h1 class="interview_preparation_title">{{ $t('report.your_interview_preparation') }}</h1>
      <img
        class="interview_preparation_img"
        src="../../../../../images/report/print/preparation.png"
        alt="preparation"
      />
    </section>
    <acing-interviews />
    <mastering-interview />
    <principle />
    <preparing />
    <interview-do />
    <interview-dont />
  </div>
</template>

<script>
import InterviewDo from './InterviewDo.vue';
import InterviewDont from './InterviewDont.vue';
import AcingInterviews from './AcingInterviews.vue';
import MasteringInterview from './MasteringInterview.vue';
import Principle from './Principle.vue';
import Preparing from './Preparing.vue';
export default {
  components: {
    InterviewDo,
    InterviewDont,
    AcingInterviews,
    MasteringInterview,
    Principle,
    Preparing,
  },
};
</script>