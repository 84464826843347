import axios from 'axios';

import { objectToQueryParams } from '../common/utils';

const getCompanyCalendars = (companyId) => {
  return axios.get(`/api/v1/companies/${companyId}/recruiter_calendars`);
};

// POST /api/v1/companies/:company_id/campaigns/:campaign_id/calendar_events
const createEvent = (companyId, campaignId, payload) => {
  return axios.post(`/api/v1/companies/${companyId}/campaigns/${campaignId}/calendar_events`, payload)
}

const getCampaignEvents = (companyId, campaignId) => {
  return axios.get(`/api/v1/companies/${companyId}/campaigns/${campaignId}/calendar_events?upcoming=true`)
}

// POST /api/v1/companies/:company_id/campaigns/:campaign_id/calendar_events/:id/send_invitation
const sendInterviewInvitations = (companyId, campaignId, eventId, payload) => {
  return axios
    .post(`/api/v1/companies/${companyId}/campaigns/${campaignId}/calendar_events/${eventId}/send_invitations`, payload)
}

// GET get event
const getEvent = (eventId, candidateId) => {
  return axios.get(`/api/v1/events/${eventId}?c=${candidateId}`)
}

const getAvailableSlots = (eventId, query) => {
  return axios.get(`/api/v1/events/${eventId}/available_slots${objectToQueryParams(query)}`);
}

const bookMeeting = (eventId, payload) => {
  return axios.post(`/api/v1/events/${eventId}/book`, payload);
}

const getBookedEvent = (eventId, bookedEventId) => {
  return axios.get(`/api/v1/events/${eventId}/booked_event/${bookedEventId}`);
}

const rescheduleBookedEvent = (eventId, bookedEventId, payload) => {
  return axios.post(`/api/v1/events/${eventId}/booked_event/${bookedEventId}/reschedule`, payload);
}

const cancelBookedEvent = (eventId, bookedEventId, payload) => {
  return axios.post(`/api/v1/events/${eventId}/booked_event/${bookedEventId}/cancel`, payload);
}


export default {
  getCompanyCalendars,
  createEvent,
  getCampaignEvents,
  sendInterviewInvitations,
  getEvent,
  getAvailableSlots,
  bookMeeting,
  getBookedEvent,
  rescheduleBookedEvent,
  cancelBookedEvent,
}