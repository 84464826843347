<template>
  <article class="campaign">
    <b-row>
      <b-col>
        <div v-if="inProgress">
          <h2 class="text-center">{{ $t('js_report.not_ready_yet') }}</h2>
          <div class="campaign__loader">
            <b-spinner
              variant="primary"
              label="Spinning"
              class="campaign__loader__spinner"
            ></b-spinner>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-breadcrumb v-if="!inProgress" :items="breadcrumbs"></b-breadcrumb>

    <!-- page header ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <header v-if="!inProgress" class="campaign__header border-bottom mb-0">
      <h1 class="campaign__title">
        {{ $t('seeker_account.my_skill_profile') }}
      </h1>
    </header>

    <!-- page content ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

    <b-row v-if="!inProgress">
      <b-col lg="9">
        <b-button-toolbar class="mt-3 justify-content-end">
          <b-button
            @click="goToReport()"
            v-b-tooltip.hover
            :title="$t('report.print_report')"
            class="btn-action report__redirect__btn"
            :class="{ primary: this.report }"
            :disabled="!this.report"
          >
            <span class="mdi mdi-printer"></span>
          </b-button>
        </b-button-toolbar>

        <b-tabs>
          <!-- // tab 1 - analytics statistics ///////////////////////////// -->

          <b-tab :title="$t('seeker_account.my_score')" class="report">
            <!-- // strengths //////////////////////////// -->

            <div class="row">
              <div class="col">
                <div class="toggle">
                  <h2
                    class="report__section__title toggle__trigger"
                    v-b-toggle.strengths
                  >
                    <span class="toggle__trigger__text text-capitalize">{{
                      $t('report.your_strengths')
                    }}</span>
                  </h2>
                  <b-collapse id="strengths" class="toggle__content pt-0">
                    <section
                      class="page-break-before-always page-break-inside-avoid page_campaign_summary"
                    >
                      <b-row>
                        <b-col>
                          <!-- // candidate values ~~~~~~~~~~~~~~~~~~~~~~~ -->

                          <report-candidate-values
                            class="pb-5"
                            :candidate-values="values"
                            :talents-attr="talentsAttributes"
                            :current-lang="currentLanguage"
                          ></report-candidate-values>

                          <!-- // core competencies ~~~~~~~~~~~~~~~~~~~~~~ -->

                          <report-core-competencies
                            :competencies="competenciesB1"
                            :talents-attr="talentsAttributes"
                            :current-lang="currentLanguage"
                          ></report-core-competencies>

                          <!-- // managerial competencies ~~~~~~~~~~~~~~~~ -->

                          <report-managerial-competencies
                            :competencies="competenciesB2"
                            :talents-attr="talentsAttributes"
                            :current-lang="currentLanguage"
                            class="mt-4"
                          ></report-managerial-competencies>

                          <!-- // traits competencies ~~~~~~~~~~~~~~~~~~~~ -->

                          <report-traits-competencies
                            :competencies="traits"
                            :talents-attr="talentsAttributes"
                            :current-lang="currentLanguage"
                            class="mt-4"
                          ></report-traits-competencies>
                        </b-col>
                      </b-row>
                    </section>
                  </b-collapse>
                </div>
              </div>
            </div>

            <!-- // core competencies //////////////////////////// -->

            <div class="row">
              <div class="col">
                <div class="toggle">
                  <h2
                    class="report__section__title toggle__trigger"
                    v-b-toggle.core_competencies
                  >
                    <span class="toggle__trigger__text text-capitalize"
                      >{{ $t('report.your_core_competencies') }}
                    </span>
                  </h2>
                  <b-collapse
                    id="core_competencies"
                    class="toggle__content pt-0"
                  >
                    <personality-types-description
                      :title="$t('report.your_core_competencies')"
                      :description="$t('report.competencies_description')"
                      :competencies="competenciesB1"
                      :talents-attr="talentsAttributes"
                      :attrDescription="attrDescription"
                      :current-lang="currentLanguage"
                      :attribute-name="'competencies'"
                      :variant="'violet'"
                      :img="coreImg"
                      :isTitle="false"
                    >
                      <report-core-competencies
                        :competencies="competenciesB1"
                        :talents-attr="talentsAttributes"
                        :current-lang="currentLanguage"
                        :print="true"
                      ></report-core-competencies
                    ></personality-types-description>
                  </b-collapse>
                </div>
              </div>
            </div>

            <!-- // personality strengths //////////////////////////// -->

            <div class="row">
              <div class="col">
                <div class="toggle">
                  <h2
                    class="report__section__title toggle__trigger"
                    v-b-toggle.personality_strengths
                  >
                    <span class="toggle__trigger__text text-capitalize"
                      >{{ $t('report.your_personality_strengths') }}
                    </span>
                  </h2>
                  <b-collapse
                    id="personality_strengths"
                    class="toggle__content pt-0"
                  >
                    <personality-types-description
                      :title="$t('report.your_personality_strengths')"
                      :subTitle="$t('report.why_personality')"
                      :description="$t('report.personality_description')"
                      :competencies="traits"
                      :talents-attr="talentsAttributes"
                      :attrDescription="attrDescription"
                      :current-lang="currentLanguage"
                      :attribute-name="'traits'"
                      :variant="'brown'"
                      :img="personalityImg"
                      :isTitle="false"
                    >
                      <report-traits-competencies
                        :competencies="traits"
                        :talents-attr="talentsAttributes"
                        :current-lang="currentLanguage"
                        :print="true"
                      ></report-traits-competencies
                    ></personality-types-description>
                  </b-collapse>
                </div>
              </div>
            </div>

            <!-- // managerial strengths //////////////////////////// -->

            <div class="row">
              <div class="col">
                <div class="toggle">
                  <h2
                    class="report__section__title toggle__trigger"
                    v-b-toggle.managerial_strengths
                  >
                    <span class="toggle__trigger__text text-capitalize"
                      >{{ $t('report.managerial_strengths') }}
                    </span>
                  </h2>
                  <b-collapse
                    id="managerial_strengths"
                    class="toggle__content pt-0"
                  >
                    <personality-types-description
                      :title="$t('report.managerial_strengths')"
                      :subTitle="$t('report.what_makes_leader')"
                      :description="$t('report.managerial_description')"
                      :competencies="competenciesB2"
                      :talents-attr="talentsAttributes"
                      :attrDescription="attrDescription"
                      :current-lang="currentLanguage"
                      :attribute-name="'competencies'"
                      :variant="'blue3'"
                      :img="potentialImg"
                      :isTitle="false"
                    >
                      <report-managerial-competencies
                        :competencies="competenciesB2"
                        :talents-attr="talentsAttributes"
                        :current-lang="currentLanguage"
                        :print="true"
                      ></report-managerial-competencies
                    ></personality-types-description>
                  </b-collapse>
                </div>
              </div>
            </div>

            <!-- // potential //////////////////////////// -->

            <div class="row">
              <div class="col">
                <div class="toggle">
                  <h2
                    class="report__section__title toggle__trigger"
                    v-b-toggle.potential
                  >
                    <span class="toggle__trigger__text text-capitalize"
                      >{{ $t('report.your_potential') }}
                    </span>
                  </h2>
                  <b-collapse id="potential" class="toggle__content pt-0">
                    <personality-types-description
                      :title="$t('report.your_potential')"
                      :subTitle="$t('report.potential_future')"
                      :description="$t('report.your_potential_description')"
                      :competencies="report.high_performance"
                      :talents-attr="talentsAttributes"
                      :attrDescription="attrDescription"
                      :current-lang="currentLanguage"
                      :attribute-name="'high_performance'"
                      :variant="'blue2'"
                      :top="3"
                      :potential="true"
                      :img="potentialArrowImg"
                      :isTitle="false"
                    >
                      <report-performance
                        :title="$t('report.performance')"
                        :report="report"
                        :talents-attr="talentsAttributes"
                        :current-lang="currentLanguage"
                        :is-blurred="false"
                        :print="true"
                      ></report-performance>
                      <report-potential
                        :title="$t('report.potential')"
                        :report="report"
                        :talents-attr="talentsAttributes"
                        :current-lang="currentLanguage"
                        :is-blurred="false"
                        :print="true"
                      ></report-potential>
                    </personality-types-description>
                    <personality-types-extensively
                      :title="$t('report.potential')"
                      :personalityType="potential"
                      :talentsAttr="talentsAttributes"
                      :attrDescription="attrDescription"
                      :currentLang="currentLanguage"
                      :attribute-name="'high_potential'"
                      :variant="'green2'"
                    />
                  </b-collapse>
                </div>
              </div>
            </div>

            <!-- // personality type //////////////////////////// -->

            <div class="row">
              <div class="col">
                <div class="toggle">
                  <h2
                    class="report__section__title toggle__trigger"
                    v-b-toggle.personality_type
                  >
                    <span class="toggle__trigger__text text-capitalize"
                      >{{ $t('report.your_personality_type') }}
                    </span>
                  </h2>
                  <b-collapse
                    id="personality_type"
                    class="toggle__content pt-0"
                  >
                    <personality-type
                      :personalityType="report.personality_type"
                      :full-name="fullName"
                      :current-lang="currentLanguage"
                      :talents-attr="talentsAttributes"
                      :is-registered="true"
                      :variant="'blue3'"
                    />
                  </b-collapse>
                </div>
              </div>
            </div>

            <!-- // interview preparation //////////////////////////// -->

            <div class="row">
              <div class="col">
                <div class="toggle">
                  <h2
                    class="report__section__title toggle__trigger"
                    v-b-toggle.interview_preparation
                  >
                    <span class="toggle__trigger__text text-capitalize"
                      >{{ $t('report.interview_preparation') }}
                    </span>
                  </h2>
                  <b-collapse
                    id="interview_preparation"
                    class="toggle__content pt-0"
                  >
                    <interview-preparation />
                  </b-collapse>
                </div>
              </div>
            </div>

            <!-- <b-row>
              <b-col>

                <div class="report">
                  <div class="report__content">

                    <b-row>
                      <b-col md="12" lg="6">
                        <div class="report__section__grouptitle">
                          <span>{{ $t("js_report.analysis_stats") }}</span>
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>



                        <div class="toggle">

                          <h2 class="report__section__title toggle__trigger" v-b-toggle.candidate-values>
                            <span class="toggle__trigger__text">{{ $t("js_report.values") }} & {{ $t("js_report.competencies") }}</span>
                          </h2>


                          <b-collapse id="candidate-values" class="toggle__content pt-0" visible>



                            <report-candidate-values
                                :candidate-values="values"
                                :talents-attr="talentsAttributes"
                                :current-lang="currentLanguage"></report-candidate-values>


                            <report-core-competencies
                                :competencies="competenciesB1"
                                :talents-attr="talentsAttributes"
                                :current-lang="currentLanguage"></report-core-competencies>



                            <report-managerial-competencies
                                :competencies="competenciesB2"
                                :talents-attr="talentsAttributes"
                                :current-lang="currentLanguage"></report-managerial-competencies>


                            <report-traits-competencies
                                :competencies="traits"
                                :talents-attr="talentsAttributes"
                                :current-lang="currentLanguage"></report-traits-competencies>
                          </b-collapse>
                        </div>


                        <div class="toggle">

                          <h2 class="report__section__title toggle__trigger" v-b-toggle.potential-analytics>
                            <span class="toggle__trigger__text">{{ $t("report.potential_analytics") }}</span>
                          </h2>



                          <b-collapse id="potential-analytics" class="toggle__content">


                            <report-potential-analytics
                                :report="report"
                                :full-name="fullName"></report-potential-analytics>

                            <report-candidate
                                :full-name="fullName"
                                :report="report"
                                :current-lang="currentLanguage"
                                :talents-attr="talentsAttributes"
                                :is-registered="isRegistered"></report-candidate>

                            <div class="d-none">
                              <p>{{ hiddenMsg }}</p>
                            </div>
                          </b-collapse>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row> -->
          </b-tab>

          <!-- // tab 2 - interview questions ////////////////////////////// -->

          <!-- <b-tab :title="$t('report.interview_questions')">
            <b-row>
              <b-col>

                <div class="report">
                  <div class="report__content">


                    <print-interview-questions :interview-questions="interviewQuestions"></print-interview-questions>

                  </div>
                </div>
              </b-col>
            </b-row>
          </b-tab> -->

          <!-- // tab 3 - interview preparation //////////////////////////// -->

          <!-- <b-tab :title="$t('js_report.your_preparation_interview')">
            <b-row>
              <b-col>

                <div class="report">
                  <div class="report__content">

                    <report-carl-principle></report-carl-principle>


                    <report-preparing-practicing></report-preparing-practicing>


                    <b-row>
                      <b-col md="12" lg="9">
                        <h2 class="report__chapter__title">
                          <span class="mdi mdi-message-alert-outline"></span>
                          <span>{{ $t('report.dos_and_donts') }} - {{ $t('report.interview.title') }}</span>
                        </h2>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="12" lg="6">
                        <report-interview-do></report-interview-do>
                      </b-col>

                      <b-col md="12" lg="6">
                        <report-interview-dont></report-interview-dont>
                      </b-col>
                    </b-row>

                  </div>
                </div>
              </b-col>
            </b-row>
          </b-tab> -->
        </b-tabs>
      </b-col>

      <!--  // aside ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

      <b-col lg="3" class="aside">
        <!--  // aside content ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

        <div class="aside__content h-100">
          <!-- INFO: Sign up is disabled until further notice -->
          <!-- <div v-if="!isRegistered" class="text-center">
            <h4>
              <span class="mdi mdi-account-plus"></span>
              <span>&nbsp;{{ $t('self_test.register_to_view_full_report') }}</span>
            </h4>
            <b-button @click="redirectToRegistration" variant="primary">{{ $t('header.sign_up') }}</b-button>
          </div> -->
        </div>
      </b-col>
    </b-row>
  </article>
</template>

<script>
import {
  FETCH_SELF_TEST_REPORT_INTERVAL,
  TALENTS_ATTRIBUTES_LOWER_VALUE_SHOWING,
  CONSOLE_LOG_MSG,
} from '@/common/constants';
import SelfTestMixin from '@/common/mixins/SelfTestMixin';
import CampaignOverview from './CampaignOverview';
import StatisticTitle from './StatisticTitle';
import CandidateValues from './CandidateValues';
import CoreCompetencies from './Candidate/CoreCompetencies';
import ManagerialCompetencies from './Candidate/ManagerialCompetencies';
import TraitsCompetencies from './Candidate/TraitsCompetencies';
import PotentialAnalytics from './PotentialAnalytics';
import Candidate from './Candidate/Index';
import CarlPrinciple from './InterviewPreparation/CarlPrinciple';
import PreparingPracticing from './InterviewPreparation/PreparingPracticing';
import InterviewDo from './InterviewPreparation/InterviewDo';
import InterviewDont from './InterviewPreparation/InterviewDont';
import InterviewQuestions from './Print/InterviewQuestions';
import CampaignDetailsAside from './CampaignDetailsAside';
import PersonalityTypesDescription from './Print/PersonalityTypesDescription';
import PersonalityTypesExtensively from './Print/PersonalityTypesExtensively';
import Performance from './Candidate/Performance';
import Potential from './Candidate/Potential';
import PersonalityType from './Print/PersonalityType';
import InterviewPreparation from './Print/InterviewPreparation/Index';
import CoreImg from '../../../images/report/print/people_elements.svg';
import Strategic from '../../../images/report/print/people_elements_24.svg';
import PersonalityImg from '../../../images/report/print/people_elements_40.svg';
import PotentialImg from '../../../images/report/print/potential.svg';
import PotentialArrowImg from '../../../images/report/print/potential_arrow.svg';

export default {
  components: {
    ReportCampaignOverview: CampaignOverview,
    ReportStatisticTitle: StatisticTitle,
    ReportCandidateValues: CandidateValues,
    ReportCoreCompetencies: CoreCompetencies,
    ReportManagerialCompetencies: ManagerialCompetencies,
    ReportTraitsCompetencies: TraitsCompetencies,
    ReportPotentialAnalytics: PotentialAnalytics,
    ReportCandidate: Candidate,
    ReportCarlPrinciple: CarlPrinciple,
    ReportPreparingPracticing: PreparingPracticing,
    ReportInterviewDo: InterviewDo,
    ReportInterviewDont: InterviewDont,
    ReportCampaignDetailsAside: CampaignDetailsAside,
    PrintInterviewQuestions: InterviewQuestions,
    ReportPerformance: Performance,
    ReportPotential: Potential,
    PersonalityTypesDescription,
    PersonalityTypesExtensively,
    PersonalityType,
    InterviewPreparation,
  },
  mixins: [SelfTestMixin],
  data() {
    return {
      reportInterval: null,
      breadcrumbs: [
        {
          text: this.$t('dashboard.dashboard'),
          to: { name: 'Home' },
        },
        {
          text: this.$t('seeker_account.my_skill_profile'),
          active: true,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch('global/populateTalentsAttributes');
    this.outputUserConsoleMsg();
  },
  mounted() {
    if (this.inProgress) {
      this.intervalGetReport(1000);
    }
  },
  beforeDestroy() {
    clearTimeout(this.reportInterval);
  },
  computed: {
    coreImg() {
      return CoreImg;
    },
    strategicImg() {
      return Strategic;
    },
    personalityImg() {
      return PersonalityImg;
    },
    potentialImg() {
      return PotentialImg;
    },
    potentialArrowImg() {
      return PotentialArrowImg;
    },
  },
  methods: {
    filterCompetenices(items) {
      return items.filter(
        (item) => item.value > TALENTS_ATTRIBUTES_LOWER_VALUE_SHOWING
      );
    },
    intervalGetReport(interval = null) {
      this.reportInterval = setTimeout(() => {
        this.$store
          .dispatch('report/getSelfTestReport', {
            token: this.currentUserToken,
            lang: this.currentLanguage,
          })
          .then(() => {
            if (this.inProgress) this.intervalGetReport();
          });
      }, interval || FETCH_SELF_TEST_REPORT_INTERVAL);
    },
    redirectToRegistration() {
      this.$router.push({ path: '/registration' });
    },
    outputUserConsoleMsg() {
      const styles = [
        'color: #000',
        'background: #E8710F',
        'font-size: 24px',
        'padding: 5px',
      ].join(';');

      //This console log is needed here for specify console log message for user
      console.log('%c%s', styles, CONSOLE_LOG_MSG);
    },
    goToReport() {
      const route = this.$router.resolve({
        name: 'SelfTestResultsPrint',
      });
      window.open(route.href, '_blank');
    },
  },
};
</script>