<template>
  <!-- Two sider progress bar slider -->
  <!--<candidate-self-report-details-table-score :report_title="$t('js_report.attributes_groups.B2')"
                                        :talents-attr="talentsAttr"
                                        :attribute-name="'competencies'"
                                        :current-lang="currentLang"
                                        :score="-1"
                                        :items="managerial_competencies"
                                        :fields="fields"
                                        :icon="'mdi mdi-hexagon-slice-3'"
                                        :max="max"></candidate-self-report-details-table-score>-->
  <!-- One sider progress bar slider -->
  <candidate-self-report-details-table
    :report_title="$t('report.managerial_strengths')"
    :talents-attr="talentsAttr"
    :attribute-name="'competencies'"
    :current-lang="currentLang"
    :score="-1"
    :variant="'blue3'"
    :items="managerial_competencies"
    :fields="fields"
    :icon="'mdi mdi-hexagon-slice-3'"
    :max="max"
    :print="print"
  ></candidate-self-report-details-table>
</template>

<script>
import { orderBy } from 'lodash';
import { SELEFTEST_VALUES_MIN_VALUE } from '@/common/constants';
//import { calculateTwoSideProgressBarValue } from '@/common/utils';
//import { TALENT_TWO_SIDE_SLIDER_BREAK_POINT } from '@/common/constants';
//import CandidateSelfReportDetailsTableScore from '../CandidateDetailsTables/CandidateSelfReportDetailsTableScore';
import CandidateSelfReportDetailsTable from '../CandidateDetailsTables/CandidateSelfReportDetailsTable';

export default {
  props: {
    competencies: {
      type: Array,
      required: true,
      default: () => {},
    },
    talentsAttr: {
      type: Object,
      required: true,
      default: () => {},
    },
    currentLang: {
      type: String,
      required: true,
      default: 'en',
    },
    print:{
      type: Boolean,
      default: false
    }
  },
  components: {
    //CandidateSelfReportDetailsTableScore,
    CandidateSelfReportDetailsTable,
  },
  data() {
    return {
      max: 100,
      fields: [
        {
          key: 'key',
          label: this.$t('create_campaign.traits'),
          thClass: 'attribute__name',
        },
        {
          key: 'value',
          thClass: '',
        },
      ],
    };
  },
  computed: {
    //Two sided progress bar slider
    /*managerial_competencies() {
      if (!this.competencies) return [];

      const values = this.competencies.map(item => {
        const progressBarWidth = calculateTwoSideProgressBarValue(
          item.value,
          TALENT_TWO_SIDE_SLIDER_BREAK_POINT
        );

        return {
          key: item.key,
          value: progressBarWidth
        };
      });

      return orderBy(values, ['value'], ['desc'])
    }*/

    //One sided progress bar slider
    managerial_competencies() {
      if (!this.competencies) return [];

      let values = this.competencies.map((item) => {
        return {
          key: item.key,
          value: item.value,
        };
      });
      values = values
        .filter((item) => item.value >= SELEFTEST_VALUES_MIN_VALUE)
        .slice(0, 5);

      return orderBy(values, ['value'], ['desc']);
    },
  },
};
</script>
