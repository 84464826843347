import axios from "axios";

// User endpoints
const patchUser = (params) => {
    return axios.patch(`/api/v1/me`, params);
};

// Calendar endpoints
const getCalendar = () => {
    return axios.get(`/api/v1/me/availability_calendar`);
};

const createCalendar = (calendarData) => {
    return axios.post(`/api/v1/me/availability_calendar`, {
        calendar: calendarData
    });
};

const updateCalendar = (calendarData) => {
    return axios.put(`/api/v1/me/availability_calendar`, {
        calendar: calendarData
    });
};

const deleteCalendar = () => {
    return axios.delete(`/api/v1/me/availability_calendar`);
};

export default {
    // User
    patchUser,

    // Calendar
    getCalendar,
    createCalendar,
    updateCalendar,
    deleteCalendar,
}