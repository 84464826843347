<template>
  <div class="booking-page">
    <!-- Header -->
    <BookingModal
      :visible="showBookingModal"
      :userName="eventCandidate.name"
      :emailAddress="eventCandidate.email"
      :isPhoneMeeting="event.event_type === 'phone'"
      :isInPersonMeeting="event.event_type === 'in_person'"
      :meetingAddress="event.location_details.location_address"
      @confirm-meeting="onMeetingBook"
      @modal-closed="showBookingModal = false"
    />

    <Confirmation v-if="showConfirmation" :timeZone="timeZone.value" />

    <div
      v-if="!showConfirmation"
      class="d-flex justify-content-center booking-container"
    >
      <div class="booking-header">
        <div class="avatar">{{ getHostInitials() }}</div>
        <div class="header-details">
          <multiselect
            v-if="hosts.length > 1"
            v-model="host"
            @select="changeHost"
            :searchable="false"
            label="name"
            selectLabel=""
            :options="hosts"
            deselectLabel=""
            :allow-empty="false"
            :hide-selected="true"
            class="mb-4"
          />
          <h2 v-else>{{ host.name }}</h2>

          <h1>{{ event.name }}</h1>
          <div class="meta-info">
            <div
              v-if="$route.meta.reschedule && bookedEvent"
              class="duration"
              style="text-decoration: line-through"
            >
              <b-icon icon="calendar"></b-icon>
              {{ new Date(bookedEvent.scheduled_at).toLocaleString() }}
            </div>
            <div class="duration">
              <b-icon icon="clock"></b-icon>
              {{ event.duration_minutes }} min
            </div>
            <div v-if="event.event_type === 'phone'" class="duration">
              <b-icon icon="telephone"></b-icon>
              {{ event.location_details.phone_number }}
            </div>
            <div v-if="event.event_type === 'in_person'" class="duration">
              <b-icon icon="geo-alt"></b-icon>
              {{ event.location_details.location_address }}
            </div>
            <div class="location">
              <b-icon icon="globe"></b-icon>
              <multiselect
                v-model="timeZone"
                :searchable="true"
                label="value"
                selectLabel=""
                :options="timeZones"
                deselectLabel=""
                @select="changeTimezone"
              />
            </div>
            <div v-if="event.description" class="duration">
              <b-icon style="align-self: flex-start" icon="info-circle" />
              <p class="m-0" style="color: #fff">
                {{ event.description }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Calendar Section -->
      <div class="calendar-section">
        <!-- Month Navigation -->
        <div class="month-nav">
          <span class="month-display">{{ currentMonthYear }}</span>
          <div class="nav-buttons">
            <button
              @click="previousMonth"
              class="nav-btn"
              aria-label="Previous month"
            >
              <b-icon icon="chevron-left"></b-icon>
            </button>
            <button @click="nextMonth" class="nav-btn" aria-label="Next month">
              <b-icon icon="chevron-right"></b-icon>
            </button>
          </div>
        </div>

        <hr class="weekday-hr m-0 p-0" />
        <div class="calendar-grid">
          <div v-for="day in getWeekDays" :key="day" class="weekday">
            {{ day }}
          </div>
        </div>
        <hr class="weekday-hr m-0 p-0 mb-3" />

        <!-- Calendar Grid -->
        <div class="calendar-grid">
          <div
            v-for="(day, index) in calendarDays"
            :key="index"
            :style="{ visibility: day.currentMonth ? 'visible' : 'hidden' }"
            :class="[
              'calendar-day',
              { 'other-month': !day.currentMonth },
              { selected: isSelectedDate(day.date) },
              { today: isToday(day.date) },
              { 'has-slots': hasDayAvailableSlots(day.date) },
            ]"
            @click="selectDate(day)"
          >
            {{ day.dayNumber }}
          </div>
        </div>
      </div>

      <!-- Time Slots -->
      <div class="time-slots-section">
        <div class="time-header">
          <span class="format-day">{{ selectedDateDisplay }}</span>
          <div class="time-format">
            <button
              :class="['format-btn', { active: timeFormat === '12' }]"
              @click="timeFormat = '12'"
            >
              12h
            </button>
            <button
              :class="['format-btn', { active: timeFormat === '24' }]"
              @click="timeFormat = '24'"
            >
              24h
            </button>
          </div>
        </div>

        <div class="time-slots">
          <button
            v-for="slot in availableTimeSlots"
            :key="slot"
            :class="['time-slot', { selected: isSelectedTime(slot) }]"
            @click="selectTime(slot)"
          >
            {{ formatTime(slot) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getInitials } from '../../common/utils';
import BookingModal from './BookingModal.vue';
import Confirmation from './Confirmation.vue';
export default {
  name: 'BookingCalendar',
  components: {
    BookingModal,
    Confirmation,
  },
  data() {
    return {
      showConfirmation: false,
      showBookingModal: false,
      timeZone: null,
      host: null,
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      currentDate: new Date(),
      selectedDate: null,
      selectedTime: null,
      timeFormat: '24',
      weekDays: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'], // TODO: Translate
      timeSlots: [],
    };
  },

  computed: {
    ...mapGetters({
      eventCompanyLogoSrc: 'calendar/eventCompanyLogoSrc',
      availableSlots: 'calendar/availableSlots',
      event: 'calendar/event',
      hosts: 'calendar/hosts',
      eventCandidate: 'calendar/eventCandidate',
      bookedEvent: 'calendar/bookedEvent',
      timeZones: 'global/timeZones',
      currentLanguage: 'global/currentLanguage',
    }),
    currentMonthYear() {
      return this.currentDate.toLocaleString(
        this.currentLanguage || 'default',
        {
          month: 'long',
          year: 'numeric',
        }
      );
    },

    selectedDateDisplay() {
      if (!this.selectedDate) return '';

      let fullDayName = new Intl.DateTimeFormat(this.currentLanguage || 'en', {
        weekday: 'long',
      }).format(this.selectedDate);

      // Take the first 3 letters and convert to uppercase
      let dayAbbr = fullDayName.substring(0, 3);

      return dayAbbr + ' ' + new Date(this.selectedDate).getDay()
    },

    calendarDays() {
      const days = [];
      const firstDay = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      );
      const lastDay = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        0
      );

      // Previous month days
      const firstDayOfWeek = firstDay.getDay();
      for (let i = firstDayOfWeek; i > 0; i--) {
        const date = new Date(firstDay);
        date.setDate(date.getDate() - i);
        days.push({
          date,
          dayNumber: date.getDate(),
          currentMonth: false,
        });
      }

      // Current month days
      for (let i = 1; i <= lastDay.getDate(); i++) {
        const date = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth(),
          i
        );
        days.push({
          date,
          dayNumber: i,
          currentMonth: true,
        });
      }

      // Next month days
      const remainingDays = 42 - days.length;
      for (let i = 1; i <= remainingDays; i++) {
        const date = new Date(lastDay);
        date.setDate(date.getDate() + i);
        days.push({
          date,
          dayNumber: date.getDate(),
          currentMonth: false,
        });
      }

      return days;
    },

    availableTimeSlots() {
      // Here you could filter time slots based on availability
      return this.timeSlots;
    },

    getWeekDays() {
      return this.getWeekdayAbbreviations(this.currentLanguage, 3);
    },
  },
  created() {
    this.selectTimezone();
    this.host = this.hosts[0];
    this.timeFormat = this.host.time_format;
    document.title = this.event.name;
  },
  methods: {
    onMeetingBook(meetingData) {
      const payload = {
        ...meetingData,
        host_id: this.host.id,
        time_zone: this.timeZone.value,
        candidate_id: this.$route.query.c,
        selected_time: this.selectedTime,
        selected_date: this.$moment(this.selectedDate).format('YYYY-MM-DD'),
      };

      if (this.$route.meta.reschedule) {
        this.$store
          .dispatch('calendar/rescheduleBookedEvent', {
            eventId: this.event.id,
            bookedEventId: this.$route.query.e,
            payload,
          })
          .then(() => {
            this.showConfirmation = true;
          });
      } else {
        this.$store
          .dispatch('calendar/bookMeeting', {
            eventId: this.event.id,
            payload,
          })
          .then(() => {
            this.showConfirmation = true;
          });
      }
    },
    fetchSlots() {
      this.$store
        .dispatch('calendar/getAvailableSlots', {
          eventId: this.event.id,
          query: {
            host_id: this.host.id,
            month: this.$moment(this.currentDate).format('YYYY-MM-DD'),
            time_zone: this.timeZone.value,
          },
        })
        .then(() => {
          const slot = this.$moment(this.selectedDate).format('YYYY-MM-DD');

          if (this.availableSlots[slot]) {
            this.timeSlots = [...this.availableSlots[slot]];
          } else {
            this.timeSlots = [];
          }
        });
    },
    changeHost(selectedOption, id) {
      this.host = selectedOption;
      this.timeFormat = this.host.time_format;
      this.fetchSlots();
    },
    changeTimezone(selectedOption, id) {
      this.timeZone = selectedOption;
      this.fetchSlots();
    },
    getHostInitials() {
      if (this.host) {
        return getInitials(this.host.name);
      }
      return 'NN';
    },
    hasDayAvailableSlots(date) {
      const slot = this.$moment(date).format('YYYY-MM-DD');
      return this.availableSlots[slot]?.length;
    },
    selectTimezone() {
      const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.timeZone = this.timeZones.find(
        (tz) => tz.value === currentTimeZone
      ) || {
        name: 'UTC',
        value: 'Etc/UTC',
      };
    },
    previousMonth() {
      const newDate = new Date(this.currentDate);
      newDate.setMonth(newDate.getMonth() - 1);
      this.currentDate = newDate;
      this.currentMonth = newDate.getMonth();
      this.currentYear = newDate.getFullYear();
      this.fetchSlots();
    },

    nextMonth() {
      const newDate = new Date(this.currentDate);
      newDate.setMonth(newDate.getMonth() + 1);
      this.currentDate = newDate;
      this.currentMonth = newDate.getMonth();
      this.currentYear = newDate.getFullYear();
      this.fetchSlots();
    },

    updateMonth(event) {
      const newDate = new Date(this.currentDate);
      newDate.setMonth(this.currentMonth);
      this.currentDate = newDate;
    },

    updateYear(event) {
      const newDate = new Date(this.currentDate);
      newDate.setFullYear(this.currentYear);
      this.currentDate = newDate;
    },

    selectDate(day) {
      if (!day.currentMonth) return;
      this.selectedDate = day.date;
      const slot = this.$moment(day.date).format('YYYY-MM-DD');
      if (this.availableSlots[slot]) {
        this.timeSlots = this.availableSlots[slot];
      } else {
        this.timeSlots = [];
      }
    },

    selectTime(time) {
      this.showBookingModal = true;
      this.selectedTime = time;
      this.$emit('selection', {
        date: this.selectedDate,
        time: this.selectedTime,
      });
    },

    isSelectedDate(date) {
      return (
        this.selectedDate &&
        date.toDateString() === this.selectedDate.toDateString()
      );
    },

    isToday(date) {
      const today = new Date();
      return date.toDateString() === today.toDateString();
    },

    isSelectedTime(time) {
      return this.selectedTime === time;
    },

    formatTime(time) {
      if (this.timeFormat === '12') {
        const [hours, minutes] = time.split(':');
        const hour = parseInt(hours);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        return `${hour12}:${minutes} ${ampm}`;
      }
      return time;
    },

    yearRange() {
      const currentYear = new Date().getFullYear();
      return Array.from({ length: 3 }, (_, i) => currentYear + i);
    },

    getWeekdayAbbreviations(locale, length = 3) {
      const weekdays = [];

      // Start with Sunday (0) through Saturday (6)
      for (let i = 0; i < 7; i++) {
        // Create a date object for a Sunday + i days
        const date = new Date(2024, 0, 7 + i);

        // Get the full weekday name in the target language
        let fullDayName = new Intl.DateTimeFormat(locale, {
          weekday: 'long',
        }).format(date);

        // Take the first 3 letters and convert to uppercase
        let dayAbbr = fullDayName.substring(0, length).toUpperCase();

        weekdays.push(dayAbbr);
      }

      return weekdays;
    },
  },
};
</script>

<style lang="scss" scoped>
.weekday-hr {
  background: #4a4a4a !important;
}

.booking-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .booking-container {
    background: #1a1a1a;
    border: 1px solid #262626;
    border-radius: 5px;
    justify-items: center;
    max-width: 1024px;
    margin: auto;
    align-content: center;

    .booking-header {
      margin-bottom: 40px;
      width: 280px;
      padding: 20px;

      .avatar {
        width: 40px;
        height: 40px;
        background: #2a2a2a;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        margin-bottom: 10px;
      }

      .header-details {
        h2 {
          font-size: 18px;
          margin: 0 0 15px;
          color: #aaa;
        }

        h1 {
          font-size: 24px;
          margin: 0 0 16px;
          color: #fff;
        }

        .meta-info {
          display: flex;
          flex-direction: column;
          gap: 20px;
          color: #aaa;

          .duration,
          .location {
            display: flex;
            align-items: center;
            gap: 8px;

            svg {
              font-size: 18px;
            }
          }
        }
      }
    }

    .calendar-section {
      // background: #2a2a2a;
      border-radius: 8px;
      padding: 20px;
      // margin-bottom: 30px;

      .month-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        padding: 10px;
        // background: #3a3a3a;
        border-radius: 8px;

        .month-display {
          font-size: 18px;
          font-weight: 500;
        }

        .nav-buttons {
          display: flex;
          gap: 10px;

          .nav-btn {
            background: #2a2a2a;
            border: 1px solid #4a4a4a;
            color: #fff;
            padding: 8px 12px;
            cursor: pointer;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 40px;

            &:hover {
              background: #4a4a4a;
            }

            i {
              font-size: 16px;
            }
          }
        }
      }

      .calendar-grid {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 1px;
        // background: #3a3a3a;

        .weekday {
          padding: 15px;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          // background: #2a2a2a;
        }

        .calendar-day {
          aspect-ratio: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          // background: #2a2a2a;
          cursor: pointer;
          font-size: 14px;
          border-radius: 5px;
          margin: 2px;

          &.other-month {
            color: #666;
          }

          &.has-slots {
            background: #2a2a2a;
          }

          &.selected {
            background: #5a5a5a;
            color: #fff;
          }

          &:hover:not(.other-month) {
            background: #3a3a3a;
          }
        }
      }
    }

    .time-slots-section {
      border-radius: 8px;
      padding: 20px;
      min-width: 230px;

      .time-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        padding: 10px;

        .format-day {
          font-size: 18px;
          font-weight: 500;
        }

        .time-format {
          display: flex;
          gap: 1px;
          border: 1px solid #4a4a4a;
          border-radius: 4px;
          overflow: hidden;

          .format-btn {
            padding: 6px 12px;
            border: 1px solid #4a4a4a;
            background: transparent;
            border: none;
            color: #fff;
            cursor: pointer;

            &.active {
              background: #3a3a3a;
            }

            &:hover:not(.active) {
              background: #3a3a3a;
            }
          }
        }
      }

      .time-slots {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        max-height: 370px;
        overflow: scroll;

        .time-slot {
          padding: 12px;
          background: #3a3a3a;
          border: none;
          color: #fff;
          border-radius: 4px;
          cursor: pointer;
          text-align: center;

          &.selected {
            background: #5a5a5a;
          }
          &:hover:not(.selected) {
            background: #4a4a4a;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .booking-container {
    flex-flow: column;
  }

  .booking-header {
    width: 489px !important;
  }
}

/* Update the calendar grid to properly fit on mobile */
@media (max-width: 480px) {
  .calendar-section {
    padding: 5px !important;
    width: 100%;
    min-height: 450px;
    height: auto;
    overflow: hidden;
  }

  .booking-header {
    width: 360px !important;
  }

  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.5rem !important;
    max-width: 316px !important;
  }

  .weekday {
    font-size: 12px;
    padding: 8px 0;
    width: 100%;
    text-align: center;
    width: 45px;
    display: flex;
    justify-content: center;
  }

  .calendar-day-wrapper {
    width: 100%;
    height: auto;
  }

  .calendar-day {
    width: 100%;
    font-size: 12px;
    aspect-ratio: 1;
    margin: 1px;
  }

  /* Update booking container for mobile */
  .booking-container {
    width: 100%;
    padding: 10px;
    border: none !important;
  }

  .time-slots {
    grid-template-columns: none !important;
    max-height: inherit !important;
    overflow: visible !important;
  }

  .time-slots-section {
    padding-top: 0 !important;
  }
}

/* For very small screens */
@media (max-width: 360px) {
  .calendar-grid {
    gap: 0.5rem !important;
    max-width: 300px !important;
  }

  .calendar-day {
    font-size: 11px;
    margin: 0;
  }

  .booking-header {
    width: 280px !important;
  }
}

/* Black theme for Vue2 Multiselect using scoped SCSS */

// With scoped styles in Vue, you need to use ::v-deep (or /deep/ or >>> depending on your setup)
// to target elements inside components

::v-deep .multiselect {
  background: none;
  color: #fff;
  border: 1px solid #333;

  &:hover {
    border-color: #333; // Keep the same border color on hover
  }

  // Selected tags container
  .multiselect__tags {
    background: none;
    border: 1px solid #333;

    &:hover {
      border-color: #333; // Keep the same border color on hover
    }
  }

  // Input field
  .multiselect__input,
  .multiselect__single {
    background: none;
    color: #fff;
    border: none; // Remove default border

    &:hover,
    &:focus {
      border: none; // Remove border on hover and focus
      outline: none; // Remove outline on focus
      box-shadow: none; // Remove any box-shadow
    }
  }

  // Dropdown container
  .multiselect__content-wrapper {
    background: #000;
    border: 1px solid #333;
  }

  // Option items
  .multiselect__option {
    background: #000;
    color: #fff;
  }

  // Highlighted option
  .multiselect__option--highlight {
    background: #333;
    color: #fff;
  }

  // Selected option
  .multiselect__option--selected {
    background: #222;
    color: #fff;
  }

  // Disabled states
  &.multiselect--disabled {
    background: #222;
    opacity: 0.6;
  }

  // Spinner
  .multiselect__spinner:before,
  .multiselect__spinner:after {
    border-color: #fff transparent transparent;
  }
}
</style>