import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  companyCalendars: [],
  eventData: null,
  availableSlots: [],
  bookedEvent: null,
  campaignEvents: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
