<template>
  <div class="report__chapter">
    <div class="row text-center mt-20">
      <div class="col-3 position-relative">
        <div class="coverpage__lineleft"></div>
      </div>
      <div class="col-6">
        <h2 class="coverpage__title w-100 text-uppercase">
          {{ $t('report.your_personality_analysis') }}
        </h2>
      </div>
      <div class="col-3 position-relative">
        <div class="coverpage__lineright">
          <span class="coverpage__date">{{ report.assessment_date }}</span>
        </div>
      </div>
    </div>
    <div class="coverpage__img">
      <img
        src="../../../../images/report/print/your_personality_traits.svg"
        alt="front page"
      />
    </div>
    <!-- table report__ems__table has__lines -->
    <table class="table coverpage__talent">
      <tbody>
        <tr>
          <td class="pb-3 w-25">{{ $t('js_report.talent_name') }}:</td>
          <td class="text-left pb-3">{{ fullName }}</td>
        </tr>
        <tr>
          <td>{{ $t('js_report.score_id') }}:</td>
          <td class="text-left">{{ report.id }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import CandidateCoverIllustration from '../../../../images/report/print/report_candidate_new.svg';

export default {
  props: {
    report: {
      type: Object,
      required: false,
      default: () => {},
    },
    fullName: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    candidateCoverIllustration() {
      return CandidateCoverIllustration;
    },
  },
};
</script>

<style lang="css" scoped>
body.print__report .report .page_campaign_cover h1,
body.print__report .report .page_candidate_cover h1 {
  width: 50%;
}

body.print__report .report .page_campaign_cover figure,
body.print__report .report .page_candidate_cover figure {
  top: 110mm;
}
</style>
