import { resourceAbsUrl } from "../../../common/utils";

const companyCalendars = state => state.companyCalendars;
const event = state => state.eventData.event;
const eventCompany = state => state.eventData.company;
const eventCompanyLogoSrc = state => resourceAbsUrl(state.eventData.company.logo_url)
const eventHosts = state => state.eventData.hosts;
const availableSlots = state => state.availableSlots;
const hosts = state => state.eventData.hosts;
const eventCandidate = state => state.eventData.candidate;
const bookedEvent = state => state.bookedEvent;
const campaignEvents = state => state.campaignEvents;

export default {
  companyCalendars,
  event,
  eventCompany,
  eventCompanyLogoSrc,
  eventHosts,
  availableSlots,
  hosts,
  eventCandidate,
  bookedEvent,
  campaignEvents,
};
