<template>
  <div class="report" :id="pageId">
    <div class="print__container">
      <b-button
        @click="printReport()"
        v-b-tooltip.hover
        variant="primary"
        :title="$t('report.print_report')"
        class="print__button"
      >
        <span class="mdi mdi-printer"></span>
      </b-button>
    </div>

    <div class="report__content print__version">
      <!-- // page: campaign cover ///////////////////////////////////////// -->

      <section class="page_campaign_cover">
        <print-header :client-name="fullName"></print-header>
        <coverpage-candidate
          :fullName="fullName"
          :report="report"
        ></coverpage-candidate>
      </section>

      <!-- // page: campaign summary /////////////////////////////////////// -->

      <section
        class="page-break-before-always page-break-inside-avoid page_campaign_summary"
      >
        <print-header :client-name="fullName"></print-header>
        <p class="report__content__text mt-30">
          <span class="font-weight-bold">{{
            $t('report.examiz_analytics')
          }}</span>
          {{ $t('report.tools_for_strategic') }}
        </p>
        <div class="row mt-10">
          <div class="offset-5 col-7">
            <img
              class="interview_do_img"
              :src="strategicImg"
              alt="acing interviews"
            />
          </div>
        </div>
      </section>

      <section
        class="page-break-before-always page-break-inside-avoid page_campaign_summary"
      >
        <print-header :client-name="fullName"></print-header>
        <table-of-contents />
      </section>

      <section
        class="page-break-before-always page-break-inside-avoid page_campaign_summary"
      >
        <print-header :client-name="fullName"></print-header>
        <report-page-header :report="report"></report-page-header>

        <b-row>
          <b-col>
            <!-- // toggle ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

            <div class="toggle">
              <h2 class="report__section__title toggle__trigger mb-5">
                <span class="toggle__trigger__text"
                  >{{ $t('js_report.values') }} &
                  {{ $t('js_report.competencies') }}</span
                >
              </h2>

              <!-- // toggle content ~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->

              <b-collapse id="candidate-values" class="toggle__content" visible>
                <!-- // candidate values ~~~~~~~~~~~~~~~~~~~~~~~ -->

                <report-candidate-values
                  :candidate-values="values"
                  :talents-attr="talentsAttributes"
                  :current-lang="currentLanguage"
                ></report-candidate-values>

                <!-- // core competencies ~~~~~~~~~~~~~~~~~~~~~~ -->

                <report-core-competencies
                  :competencies="competenciesB1"
                  :talents-attr="talentsAttributes"
                  :current-lang="currentLanguage"
                ></report-core-competencies>

                <!-- // managerial competencies ~~~~~~~~~~~~~~~~ -->

                <report-managerial-competencies
                  :competencies="competenciesB2"
                  :talents-attr="talentsAttributes"
                  :current-lang="currentLanguage"
                  class="mt-4"
                ></report-managerial-competencies>

                <!-- // traits competencies ~~~~~~~~~~~~~~~~~~~~ -->

                <report-traits-competencies
                  :competencies="traits"
                  :talents-attr="talentsAttributes"
                  :current-lang="currentLanguage"
                  class="mt-4"
                ></report-traits-competencies>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
      </section>

      <!-- // core competencies ~~~~~~~~~~~~~~~~~~~~~~ -->

      <personality-types-description
        :title="$t('report.your_core_competencies')"
        :description="$t('report.competencies_description')"
        :competencies="competenciesB1"
        :talents-attr="talentsAttributes"
        :attrDescription="attrDescription"
        :current-lang="currentLanguage"
        :attribute-name="'competencies'"
        :variant="'violet'"
        :img="coreImg"
      >
        <report-core-competencies
          :competencies="competenciesB1"
          :talents-attr="talentsAttributes"
          :current-lang="currentLanguage"
          :print="true"
        ></report-core-competencies
      ></personality-types-description>

      <!-- // traits competencies ~~~~~~~~~~~~~~~~~~~~ -->

      <personality-types-description
        :title="$t('report.your_personality_strengths')"
        :subTitle="$t('report.why_personality')"
        :description="$t('report.personality_description')"
        :competencies="traits"
        :talents-attr="talentsAttributes"
        :attrDescription="attrDescription"
        :current-lang="currentLanguage"
        :attribute-name="'traits'"
        :variant="'brown'"
        :img="personalityImg"
      >
        <report-traits-competencies
          :competencies="traits"
          :talents-attr="talentsAttributes"
          :current-lang="currentLanguage"
          :print="true"
        ></report-traits-competencies
      ></personality-types-description>

      <!-- // managerial competencies ~~~~~~~~~~~~~~~~ -->

      <personality-types-description
        :title="$t('report.managerial_strengths')"
        :subTitle="$t('report.what_makes_leader')"
        :description="$t('report.managerial_description')"
        :competencies="competenciesB2"
        :talents-attr="talentsAttributes"
        :attrDescription="attrDescription"
        :current-lang="currentLanguage"
        :attribute-name="'competencies'"
        :variant="'blue3'"
        :img="potentialImg"
      >
        <report-managerial-competencies
          :competencies="competenciesB2"
          :talents-attr="talentsAttributes"
          :current-lang="currentLanguage"
          :print="true"
        ></report-managerial-competencies
      ></personality-types-description>

      <!-- // performance ~~~~~~~~~~~~~~~~~~~~ -->

      <personality-types-description
        :title="$t('report.your_potential')"
        :subTitle="$t('report.potential_future')"
        :description="$t('report.your_potential_description')"
        :competencies="report.high_performance"
        :talents-attr="talentsAttributes"
        :attrDescription="attrDescription"
        :current-lang="currentLanguage"
        :attribute-name="'high_performance'"
        :variant="'blue2'"
        :top="3"
        :potential="true"
        :img="potentialArrowImg"
      >
        <report-performance
          :title="$t('report.performance')"
          :report="report"
          :talents-attr="talentsAttributes"
          :current-lang="currentLanguage"
          :is-blurred="false"
          :print="true"
          :show-title="true"
        ></report-performance>
        <report-potential
          :title="$t('report.potential')"
          :report="report"
          :talents-attr="talentsAttributes"
          :current-lang="currentLanguage"
          :is-blurred="false"
          :print="true"
          :show-title="true"
        ></report-potential>
      </personality-types-description>

      <!-- // potential  ~~~~~~~~~~~~~~~~~~~~ -->

      <personality-types-extensively
        :title="$t('report.potential')"
        :personalityType="potential"
        :talentsAttr="talentsAttributes"
        :attrDescription="attrDescription"
        :currentLang="currentLanguage"
        :attribute-name="'high_potential'"
        :variant="'green2'"
      />

      <!-- // personality type  ~~~~~~~~~~~~~~~~~~~~ -->

      <personality-type
        :personalityType="report.personality_type"
        :full-name="fullName"
        :current-lang="currentLanguage"
        :talents-attr="talentsAttributes"
        :is-registered="true"
        :variant="'blue3'"
      />

      <interview-preparation />
    </div>
    <ReportFooter />
  </div>
</template>

<script>
import CoreImg from '../../../images/report/print/people_elements.svg';
import Strategic from '../../../images/report/print/people_elements_24.svg';
import PersonalityImg from '../../../images/report/print/people_elements_40.svg';
import PotentialImg from '../../../images/report/print/potential.svg';
import PotentialArrowImg from '../../../images/report/print/potential_arrow.svg';
import SelfTestMixin from '@/common/mixins/SelfTestMixin';
import { TALENTS_ATTRIBUTES_LOWER_VALUE_SHOWING } from '@/common/constants';
import ReportFooter from '@/components/FooterPrint';
import PrintHeader from '../Report/Print/PrintHeader';
import CoverpageCandidate from './Print/CoverpageCandidate';
import ReportPageHeader from './Candidate/ReportPageHeader';
import CandidateValues from './CandidateValues';
import CoreCompetencies from './Candidate/CoreCompetencies';
import ManagerialCompetencies from './Candidate/ManagerialCompetencies';
import TraitsCompetencies from './Candidate/TraitsCompetencies';
import PotentialAnalytics from './PotentialAnalytics';
import PrintInterviewQuestions from './Print/PrintInterviewQuestions';
import Candidate from './Candidate/Index';
import PersonalityTypesDescription from './Print/PersonalityTypesDescription';
import PersonalityTypesExtensively from './Print/PersonalityTypesExtensively';
import PersonalityType from './Print/PersonalityType';
import Performance from './Candidate/Performance';
import Potential from './Candidate/Potential';
import HeaderTitle from './Print/HeaderTitle';
import InterviewPreparation from './Print/InterviewPreparation/Index';
import TableOfContents from './Print/TableOfContents';

export default {
  name: 'SelfTestResultsPrint',
  props: {
    pageId: {
      type: String,
      default: 'printreport__fullview',
    },
  },
  components: {
    PrintHeader,
    CoverpageCandidate,
    ReportPageHeader,
    ReportCandidateValues: CandidateValues,
    ReportCoreCompetencies: CoreCompetencies,
    ReportManagerialCompetencies: ManagerialCompetencies,
    ReportTraitsCompetencies: TraitsCompetencies,
    ReportPotentialAnalytics: PotentialAnalytics,
    ReportCandidate: Candidate,
    ReportPerformance: Performance,
    ReportPotential: Potential,
    TableOfContents,
    PrintInterviewQuestions,
    PersonalityTypesDescription,
    PersonalityTypesExtensively,
    InterviewPreparation,
    PersonalityType,
    HeaderTitle,
    ReportFooter,
  },
  mixins: [SelfTestMixin],
  created() {
    this.$store.dispatch('global/populateTalentsAttributes');
    document.body.classList.add('print__report');
  },
  computed: {
    coreImg() {
      return CoreImg;
    },
    strategicImg() {
      return Strategic;
    },
    personalityImg() {
      return PersonalityImg;
    },
    potentialImg() {
      return PotentialImg;
    },
    potentialArrowImg() {
      return PotentialArrowImg;
    },
  },
  mounted() {
    this.setPageNumbers();
  },
  methods: {
    filterCompetenices(items) {
      return items.filter(
        (item) => item.value > TALENTS_ATTRIBUTES_LOWER_VALUE_SHOWING
      );
    },
    printReport() {
      window.print();
    },
    setPageNumbers() {
      const pages = document.querySelectorAll(
        '.page-break-before-always, .page_campaign_cover'
      );

      pages.forEach((page, index) => {
        page.classList.add('report__pages');
        const pageNumber = document.createElement('h6');
        pageNumber.className = 'report__page__count';
        pageNumber.textContent = `${index + 1}`;
        page.appendChild(pageNumber);
      });
    },
  },
};
</script>
<style lang="scss">
@import '/styles/print';

body.print__report .report .report__chapter__title {
  margin: 26px 0 8px 0;
}
</style>
