<template>
  <div>
    <b-button
      @click="
        () => {
          modalShow = true;
        }
      "
      variant="outline-primary"
      class="h-auto"
    >
      {{ $t('calendar.invite_selected') }}
    </b-button>
    <b-modal
      v-model="modalShow"
      :title="$t('calendar.schedule_interview')"
      ok-title="Invite"
      centered
      @ok="handleSubmit"
      @hidden="resetValidation"
    >
      <b-form>
        <!-- Event Name -->
        <b-form-group
          :label="$t('general.name')"
          label-for="event-name"
          :state="validationState.name"
          :invalid-feedback="$t('calendar.errors.name_is_required')"
        >
          <b-form-input
            id="event-name"
            v-model="eventData.name"
            :state="validationState.name"
            required
            :placeholder="$t('calendar.placeholder.event_name')"
          ></b-form-input>
        </b-form-group>

        <!-- Event Type -->
        <b-form-group
          :label="$t('general.type')"
          label-for="event-type"
          :state="validationState.type"
          :invalid-feedback="$t('calendar.errors.interview_type_is_required')"
        >
          <b-form-select
            id="event-type"
            v-model="eventData.type"
            :options="typeOptions"
            :state="validationState.type"
            required
          ></b-form-select>
        </b-form-group>

        <!-- Phone Number (only for phone type) -->
        <div v-if="eventData.type === 'phone'">
          <b-form-group
            :label="$t('auth_pages.phone_number')"
            label-for="phone-number"
            :state="validationState.phone_number"
            :invalid-feedback="$t('calendar.errors.phone_number_is_required')"
          >
            <b-form-input
              id="phone-number"
              v-model="eventData.phone_number"
              :state="validationState.phone_number"
              placeholder="+1 123-456-7890"
            ></b-form-input>
          </b-form-group>
        </div>

        <!-- Location (only for in-person type) -->
        <div v-if="eventData.type === 'in_person'">
          <b-form-group
            :label="$t('create_campaign.location_office')"
            label-for="location"
            :state="validationState.address"
            :invalid-feedback="$t('calendar.errors.location_is_required')"
          >
            <b-form-textarea
              id="in_person"
              v-model="eventData.address"
              :state="validationState.address"
              rows="2"
              :placeholder="$t('calendar.placeholder.office_address')"
            ></b-form-textarea>
          </b-form-group>
        </div>

        <!-- Duration -->
        <b-form-group
          :label="$t('calendar.duration_in_minutes')"
          label-for="duration"
          :state="validationState.durationMinutes"
          :invalid-feedback="$t('calendar.errors.duration_1_minute')"
        >
          <b-form-input
            id="duration"
            v-model.number="eventData.durationMinutes"
            :state="validationState.durationMinutes"
            required
            type="number"
            min="1"
          ></b-form-input>
        </b-form-group>

        <!-- Description (optional) -->
        <b-form-group
          :label="$t('general.description')"
          label-for="description"
        >
          <b-form-textarea
            id="description"
            v-model="eventData.description"
            rows="3"
            :placeholder="$t('calendar.placeholder.additional_details')"
          ></b-form-textarea>
        </b-form-group>

        <!-- Recruiters -->
        <b-form-group
          :label="$t('calendar.host_s')"
          label-for="recruiters"
          :state="validationState.selectedRecruiters"
          :invalid-feedback="$t('calendar.errors.recruiter_must_be_selected')"
        >
          <multiselect
            v-model="eventData.selectedRecruiters"
            :options="recruiters"
            :multiple="true"
            :custom-label="formatRecruiterLabel"
            track-by="id"
            :class="{
              'multiselect-error':
                !validationState.selectedRecruiters && isSubmitted,
            }"
            :selectLabel="''"
            :deselectLabel="''"
            :selectedLabel="$t('general.selected')"
          ></multiselect>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modalShow: false,
      recruiters: [],
      isSubmitted: false,
      eventData: {
        name: '',
        type: 'video',
        typeValue: '',
        durationMinutes: 30,
        description: '',
        selectedRecruiters: [],
        phone_number: '',
        address: '',
      },
      validationState: {
        name: null,
        type: null,
        phone_number: null,
        address: null,
        durationMinutes: null,
        selectedRecruiters: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      companyCalendars: 'calendar/companyCalendars',
      currentUserId: 'global/currentUserId',
    }),
    typeOptions() {
      return [
        { value: 'video', text: 'Video Call' },
        { value: 'phone', text: 'Phone Call' },
        { value: 'in_person', text: 'In-Person Meeting' },
      ];
    },
  },
  watch: {
    modalShow(val) {
      if (val) {
        this.$store.dispatch('calendar/getCompanyCalendars').then(() => {
          this.recruiters = this.companyCalendars;

          const currentRecruiter = this.companyCalendars.find(
            (c) => c.id === this.currentUserId
          );
          if (currentRecruiter) {
            this.eventData = {
              ...this.eventData,
              selectedRecruiters: [currentRecruiter],
              address:
                currentRecruiter.availability_calendar.contact_info.address,
              phone_number:
                currentRecruiter.availability_calendar.contact_info
                  .phone_number,
            };
          }
        });
      }
    },
  },
  created() {
    if (this.name) {
      this.eventData.name = `${this.name} Interview`
        .replace(/\s+/g, ' ')
        .trim();
    }
  },
  methods: {
    formatRecruiterLabel(recruiter) {
      return `${recruiter.first_name} ${recruiter.last_name} (${recruiter.email})`;
    },

    validateForm() {
      this.isSubmitted = true;
      const validation = {
        name: !!this.eventData.name.trim(),
        type: !!this.eventData.type,
        phone_number:
          this.eventData.type !== 'phone' ||
          !!this.eventData.phone_number?.trim(),
        address:
          this.eventData.type !== 'in_person' ||
          !!this.eventData.address?.trim(),
        durationMinutes: this.eventData.durationMinutes > 0,
        selectedRecruiters: this.eventData.selectedRecruiters?.length > 0,
      };

      this.validationState = validation;
      return Object.values(validation).every((v) => v === true);
    },

    handleSubmit(event) {
      event.preventDefault();
      if (!this.validateForm()) {
        return;
      }
      this.sendInvitation();
    },

    sendInvitation() {
      const eventData = {
        name: this.eventData.name,
        type: this.eventData.type,
        typeValue: this.eventData.typeValue,
        durationMinutes: this.eventData.durationMinutes,
        description: this.eventData.description,
        phone_number: this.eventData.phone_number,
        address: this.eventData.address,
        recruiters: this.eventData.selectedRecruiters.map((r) => r.id),
      };

      this.$emit('invite', eventData);
      this.modalShow = false;
    },

    resetValidation() {
      this.isSubmitted = false;
      this.validationState = {
        name: null,
        type: null,
        phone_number: null,
        address: null,
        durationMinutes: null,
        selectedRecruiters: null,
      };
    },

    resetForm() {
      this.eventData = {
        name: '',
        type: 'video',
        typeValue: '',
        durationMinutes: 30,
        description: '',
        selectedRecruiters: [],
        phone_number: '',
        address: '',
      };
      this.resetValidation();
      this.modalShow = false;
    },
  },
};
</script>

<style scoped>
.invite-icon {
  font-size: 1.25rem;
  cursor: pointer;
  color: #007bff;
}
</style>