<template>
  <div class="print__attribute__header">
    <b-row>
      <b-col md="4" lg="4" class="col-print-4">
        <div class="header__attribute__label">{{ $t('js_report.assessment_date') }}</div>
        <div class="header__attribute__value">{{ report.assessment_date }}</div>
      </b-col>

      <b-col md="4" lg="4" class="col-print-4">
        <div class="header__attribute__label">{{ $t('report.analysis_id') }}</div>
        <div class="header__attribute__value">{{ report.id }}</div>
      </b-col>

      <b-col md="4" lg="4" class="col-print-4"></b-col>
    </b-row>
  </div>

</template>

<script>
export default {
  props: {
    report: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
};
</script>
