<template>
  <div class="campaign-calendar">
    <!-- <div v-if="loading" class="d-flex justify-content-center my-4">
      <b-spinner label="Loading..."></b-spinner>
    </div> -->

    <div v-if="!campaignEvents.length" class="emptystate">
      <i class="mdi mdi-calendar-clock"></i>
      <p>{{ $t('campaign.no_calendar_bookings') }}</p>
    </div>

    <div v-else>
      <div class="calendar-controls mb-3 d-flex justify-content-between">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input
              v-model="filter"
              placeholder="Search bookings..."
              @input="onFilterChange"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                <i class="mdi mdi-close"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-dropdown right text="Filter status" class="ml-2">
          <b-dropdown-item
            v-for="status in statusOptions"
            :key="status.value"
            @click="filterByStatus(status.value)"
            :active="currentStatusFilter === status.value"
          >
            {{ status.text }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <b-table
        :items="filteredBookings"
        :fields="fields"
        striped
        hover
        responsive
        class="calendar-table"
      >
        <template v-slot:cell(booking_time)="data">
          <div>
            <div class="font-weight-bold">
              {{ formatDate(data.item.booking_date) }}
            </div>
            <div>{{ formatTime(data.item.scheduled_at) }}</div>
          </div>
        </template>

        <template v-slot:cell(candidate)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-weight-bold">
                {{ data.item.campaign_candidate.first_name }}
                {{ data.item.campaign_candidate.last_name }}
              </div>
              <div class="small text-muted">
                {{ data.item.campaign_candidate.email_1 }}
              </div>
            </div>
          </div>
        </template>

        <template v-slot:cell(host)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-weight-bold">
                {{ data.item.host.first_name }} {{ data.item.host.last_name }}
              </div>
              <div class="small text-muted">{{ data.item.host.email }}</div>
            </div>
          </div>
        </template>

        <template v-slot:cell(location)="data">
          <div>
            <a
              v-if="data.item.video_meeting_link"
              :href="data.item.video_meeting_link"
              target="_blank"
              >Video link</a
            >
            <span>{{ data.item.location }}</span>
          </div>
        </template>

        <template v-slot:cell(status)="data">
          <b-badge :variant="getStatusVariant(data.item.status)">
            {{ $t(`calendar.meeting_status.${data.item.status}`) }}
          </b-badge>
        </template>

        <template v-slot:cell(actions)="data">
          <div class="action-buttons">
            <b-button
              variant="outline-primary"
              size="sm"
              class="mr-2"
              @click="reschedule(data.item)"
              v-b-tooltip.hover
              title="Reschedule"
            >
              <i class="mdi mdi-calendar-clock"></i>
            </b-button>
            <b-button
              variant="outline-danger"
              size="sm"
              class="mr-2"
              @click="cancel(data.item)"
              v-b-tooltip.hover
              title="Cancel"
            >
              <i class="mdi mdi-calendar-remove"></i>
            </b-button>
            <b-button
              v-if="data.item.additional_notes || data.item.cancellation_reason"
              variant="outline-success"
              size="sm"
              class="mr-2"
              @click="info(data.item)"
              v-b-tooltip.hover
              title="Info"
            >
              <i class="mdi mdi-information"></i>
            </b-button>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-if="totalRows > perPage"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        class="mt-3"
      ></b-pagination>
    </div>

    <!-- Cancel Confirmation Modal -->
    <b-modal
      id="cancel-booking-modal"
      ref="cancelModal"
      v-model="showInfoModal"
      centered
      title="Info"
      cancel-disabled
      :ok-title="$t('general.ok')"
    >
      <label v-if="bookingInfo.additionalNotes" class="mb-2">{{ $t('calendar.additional_notes') }}</label>
      <p>{{ bookingInfo.additionalNotes }}</p>

      <label v-if="bookingInfo.cancellationReason" class="mb-2">{{ $t('calendar.reason_for_cancellation') }}</label>
      <p>{{ bookingInfo.cancellationReason }}</p>
      <template #modal-footer="{ ok }">
        <b-button size="sm" variant="success" @click="ok()"> OK </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CampaignCalendar',
  props: {
    campaignId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showInfoModal: false,
      bookingInfo: {
        additionalNotes: '',
        cancellationReason: '',
      },
      loading: true,
      filteredBookings: [],
      filter: '',
      currentStatusFilter: 'all',
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      fields: [
        { key: 'booking_time', label: 'Booking Time', sortable: false },
        { key: 'candidate', label: 'Candidate', sortable: false },
        { key: 'host', label: 'Host', sortable: false },
        { key: 'location', label: 'Location', sortable: false },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'actions', label: 'Actions' },
      ],
      statusOptions: [
        { value: 'all', text: 'All' },
        {
          value: 'confirmed',
          text: this.$t('calendar.meeting_status.confirmed'),
        },
        {
          value: 'cancelled',
          text: this.$t('calendar.meeting_status.cancelled'),
        },
        // { value: 'completed', text: this.$t('calendar.meeting_status.completed') },
        {
          value: 'rescheduled',
          text: this.$t('calendar.meeting_status.rescheduled'),
        },
      ],
      selectedBooking: null,
      cancelReason: '',
    };
  },
  computed: {
    ...mapGetters({
      campaignEvents: 'calendar/campaignEvents',
    }),
  },
  mounted() {
    this.fetchCalendarBookings();
  },
  methods: {
    fetchCalendarBookings() {
      this.$store
        .dispatch('calendar/getCampaignEvents', {
          campaignId: this.campaignId,
        })
        .then(() => {
          this.filteredBookings = this.campaignEvents;
        });
    },
    formatDate(dateString) {
      return dateString;
    },

    formatTime(timeString) {
      const date = new Date(timeString);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },

    getInitials(name) {
      if (!name) return '';
      return name
        .split(' ')
        .map((part) => part.charAt(0).toUpperCase())
        .join('')
        .substring(0, 2);
    },

    getStatusVariant(status) {
      const variantMap = {
        confirmed: 'primary',
        completed: 'success',
        cancelled: 'danger',
        rescheduled: 'warning',
      };
      return variantMap[status] || 'secondary';
    },
    info(event) {
      this.bookingInfo = {
        additionalNotes: event.additional_notes,
        cancellationReason: event.cancellation_reason,
      };
      this.showInfoModal = true;
    },
    reschedule(event) {
      window.open(
        `/book/${event.calendar_event_id}/reschedule?e=${event.id}&c=${event.campaign_candidate_id}`,
        '_blank'
      );
    },
    cancel(event) {
      window.open(
        `/book/${event.calendar_event_id}/cancel?e=${event.id}&c=${event.campaign_candidate_id}`,
        '_blank'
      );
    },

    onFilterChange() {
      this.applyFilters();
    },

    filterByStatus(statusValue) {
      this.currentStatusFilter = statusValue;
      this.applyFilters();
    },

    applyFilters() {
      // Apply both text filter and status filter
      let filtered = [...this.campaignEvents];

      // Apply status filter
      if (this.currentStatusFilter !== 'all') {
        filtered = filtered.filter(
          (booking) => booking.status === this.currentStatusFilter
        );
      }

      // Apply text filter if there is any
      if (this.filter) {
        const searchTerm = this.filter.toLowerCase();
        filtered = filtered.filter(
          (booking) =>
            booking.candidate.name.toLowerCase().includes(searchTerm) ||
            booking.host.name.toLowerCase().includes(searchTerm) ||
            booking.meeting_type.toLowerCase().includes(searchTerm)
        );
      }

      this.filteredBookings = filtered;
      this.totalRows = this.filteredBookings.length;
      this.currentPage = 1; // Reset to first page after filtering
    },
  },
};
</script>

<style scoped>
.campaign-calendar {
  padding: 1rem 0;
}

.calendar-table {
  font-size: 1.2rem;
}

.emptystate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  color: #6c757d;
  text-align: center;
}

.emptystate i {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.action-buttons {
  white-space: nowrap;
}

.candidate-avatar,
.host-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
</style>