import env from '../../config/enviroment';
import { MATCH_TWO_SIDE_SLIDER_BREAK_POINT } from './constants'

const resourceAbsUrl = (url) => {
  return url.startsWith('http') ? url : `${env.API_URL}${url}`
}

const checkAbsUrl = (url) => {
  return (url.startsWith('http') || url.startsWith('https')) ? url : 'https://' + url
}

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const clearLocalStorage = () => {
  const lang = localStorage.getItem('language');
  localStorage.clear();
  localStorage.setItem('language', lang);
}

const calculateCampaignProgress = (passed, requested) => {
  if (requested === 0) requested = 1;
  let progress = ((passed / requested) * 100);
  if (progress > 100) progress = 100;
  return progress.toFixed(0);
}

const calculateTwoSideProgressBarValue = (value, breakPoint = MATCH_TWO_SIDE_SLIDER_BREAK_POINT) => {
  const spanLeft = breakPoint;
  const spanRight = 100 - breakPoint;
  const diff = value - breakPoint;
  let barLengthValue = diff >= 0 ? Math.round((diff * 100 / spanRight)) : Math.round((diff * 100 / spanLeft))

  if (barLengthValue >= 0.0 && barLengthValue < 5.0) {
    barLengthValue = 5;
  } else if (barLengthValue < 0.0 && barLengthValue > -5.0) {
    barLengthValue = -5;
  }
  return barLengthValue;
}

const getInitials = (name, maxInitials = 2) => {
  // Return empty string if name is empty or not a string
  if (!name || typeof name !== 'string') {
    return '';
  }

  // Split the name into parts and filter out empty parts
  const nameParts = name.split(' ').filter(part => part.length > 0);

  // If no valid parts found, return empty string
  if (nameParts.length === 0) {
    return '';
  }

  // Get first letter of each part, up to maxInitials
  const initials = nameParts
    .slice(0, maxInitials)
    .map(part => part[0].toUpperCase())
    .join('');

  return initials;
}

function objectToQueryParams(obj, encodeValues = true) {
  // Return empty string if input is not an object or is null
  if (!obj || typeof obj !== 'object' || Array.isArray(obj)) {
    return '';
  }

  // Convert object to query string
  const params = Object.entries(obj)
    .filter(([_, value]) => value !== undefined && value !== null) // Skip undefined and null values
    .map(([key, value]) => {
      // Handle arrays
      if (Array.isArray(value)) {
        return value
          .map(item => `${key}=${encodeValues ? encodeURIComponent(item) : item}`)
          .join('&');
      }

      // Handle objects (convert to JSON string)
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }

      // Handle standard values
      return `${key}=${encodeValues ? encodeURIComponent(value) : value}`;
    })
    .join('&');

  // Return with leading '?' if there are params
  return params ? `?${params}` : '';
}

export {
  resourceAbsUrl,
  randomIntFromInterval,
  clearLocalStorage,
  calculateCampaignProgress,
  calculateTwoSideProgressBarValue,
  checkAbsUrl,
  getInitials,
  objectToQueryParams,
}
